import React from "react";
import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  CircularProgress

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AssessmentTestController, {
  Props,
} from "./AssessmentTestController";
import { AssessmentItemInterface, AttemptedAssessmentInterface } from "./IAssessment";

export default class AssessmentTest extends AssessmentTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  renderAssessmentTestWebLoader() {
    if (this.state.isLoading) return (
      <Box
        data-test-id={"loaderContainer"}
        style={{
          height: '100vh',
          width:'100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          
        <CircularProgress style={{
          height:'100px',
          width: '100px'
        }} />
        <h1>Loading...</h1>
      </Box>
    )
  }

  renderAssessmentTestWebError() {
    if (this.state.isError) return (

      <Box
        className=""
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <Typography

          variant="subtitle1"
          component="p"
          data-test-id={"errorMessageId"}
        >
          Something went wrong, please try again {/*UI Engine::From Sketch*/}
        </Typography>
        <Button
          data-test-id={"retryButtonId"}
          style={{
            backgroundColor: '#210042',
            margin: '0rem'
          }}
          onClick={ this.onReFetch}
          variant="contained"
          color="primary"

        >
          Retry
        </Button>
      </Box>
    )
  }

  renderTab() {
    return (
      <Box sx={{
        minWidth: "300px",
        display: "flex",
        alignItems: "center",
        marginBottom: "2rem",
      }}>
        <Button
          style={{
            marginRight: '1.2rem',
            backgroundColor: this.state.tabNum === 1 ? '#210042' : 'rgba(128, 128, 128, 0.499)'
          }}

          data-test-id="tabBtnAssessmentId"
          variant="contained"
          color="primary"
          onClick={() => this.onTabClick(1)}
        >
          Assessment test {/*UI Engine::From Sketch*/}
        </Button>
        <Button
          style={{
            backgroundColor: this.state.tabNum === 2 ? '#210042' : 'rgba(128, 128, 128, 0.499)'
          }}
          data-test-id="tabBtnAttemptedAssessment"
          variant="contained"
          color="primary"
          onClick={() => this.onTabClick(2)}
        >
          Attempted {/*UI Engine::From Sketch*/}
        </Button>
      </Box>

    )
  }


  renderAssessmentList() {

    if (this.state.tabNum === 1) return (
      <>
        {this.renderCategoriesList()}
        <Input
          type="outlined"
          data-test-id="searchInputId"
          placeholder={"Search"}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            this.handleSearch(event.target.value)
          }}
        />

        <Box
        data-test-id="uncompleted"
          style={{
            display: "grid",
            gap: "20px 16px",
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))"
          }}>
          {
            this.unCompletedListAssessment().map((item: AssessmentItemInterface, index: number) => {
              return (
                <Box
                  sx={{ ...webstyles.card }}
                  key={`${item.id}${index}`} >
                  <Box
                    style={{
                      position: "relative",
                      height: "160px",
                      backgroundColor: "rgb(81, 81, 81)"
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center"
                      }}
                      src={item.attributes?.image} alt="" />
                  </Box>
                  <Box
                    style={{
                      padding: "24px 16px"
                    }}
                  >
                    <Typography
                      style={{
                        marginBottom: '1rem'
                      }}
                      component="p">
                      {item?.attributes?.name}
                    </Typography>
                    <Typography
                    data-test-id="convertedTime"
                      style={{
                        marginBottom: '1rem'
                      }}
                      component="p">
                      Total Time:  {this.convertMinutesToHoursAndMinutes(item.attributes?.duration ? item.attributes?.duration : 0)}
                    </Typography>
                    <Typography
                      style={{
                        marginBottom: '1rem'
                      }}
                      component="p">
                      Total Question:
                      {item?.attributes?.number_of_questions}
                    </Typography>
                    <Button
                      style={{
                        backgroundColor: '#210042'
                      }}
                      data-test-id={"startBtn"}
                      variant="contained"
                      color="primary"
                      onClick={() => this.onSelected(item)}
                    >
                      Start test {/*UI Engine::From Sketch*/}
                    </Button>
                  </Box>
                </Box>
              )
            })
          }
          {this.unCompletedListAssessment().length === 0 && (
            <Typography
              style={{
                fontSize: '1.3rem'
              }}
              variant="subtitle1" component="p">
              No assessment found
            </Typography>

          )}

        </Box>
      </>
    )
  }

  renderCategoriesList() {
    return (
      <>
        <Typography variant="subtitle1" component="div">
          Categories {/*UI Engine::From Sketch*/}
        </Typography>
        <Box
          data-test-id={"categoryListId"}
          style={{
            display: "flex",
            overflowX: "scroll",
            width: "100vw",
            marginBottom: '2rem'
          }}
        >
          {
           Array.isArray(this.state.assessmentCategories) && this.state.assessmentCategories.map((item: AssessmentItemInterface) => {
              return (
                <>
                  <Button
                    style={{
                      backgroundColor: this.state.selectedId === item.id ? '#210042' : 'rgba(128, 128, 128, 0.5)',
                      marginRight: '1rem'
                    }}
                    data-test-id={"selectedCat"}
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleCatSelected(item)}
                  >
                    {item?.attributes?.name} {/*UI Engine::From Sketch*/}
                  </Button>
                </>
              )
            })
          }
        </Box>
      </>
    )
  }
  renderAttemptedAssessment() {
    if (this.state.tabNum === 2) return (
      <Box
        data-test-id={'attemptedAssessment'}
        style={{
          display: "grid",
          gap: "20px 16px",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))"
        }}
      >
        {
          this.state.attemptedAssessment.map((item: AttemptedAssessmentInterface, index: number) => {
            return (
              <Box
                sx={{ ...webstyles.card }}
                key={`${item.id}${index}`} >
                <Box
                  style={{
                    position: "relative",
                    height: "160px",
                    backgroundColor: "rgb(81, 81, 81)"
                  }}

                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center"
                    }}
                    src={item.attributes?.image} alt=""
                  />
                </Box>
                <Box
                data-test-id="attemptedAssessmentChild"
                  style={{
                    padding: "24px 16px"
                  }}
                >
                  <Typography
                    style={{
                      marginBottom: '1rem'
                    }}
                    component="p">
                    {item?.attributes?.assessment_name}
                  </Typography>

                  <Button
                    style={{
                      backgroundColor: '#210042'
                    }}
                    data-test-id={"attemptSelectedBtn"}
                    variant="contained"
                    color="primary"
                    onClick={() => this.onAttemptedSelected(item)}
                  >
                    View result {/*UI Engine::From Sketch*/}
                  </Button>
                </Box>
              </Box>
            )
          })
        }
        {this.state.attemptedAssessment.length === 0 && (
          <Typography
          data-test-id="noAssessment"
            style={{
              fontSize: '1.3rem'
            }}
            variant="subtitle1" component="p">
            No attempted assessment found
          </Typography>
        )}
      </Box>
    )
  }



  renderContent() {
    if (this.state.isSuccess) return (
      <>
        <Box style={{
          position: "relative",
          padding: "12px 30px",
          background: "rgb(37, 0, 65)",
          color: "rgb(255, 255, 255)",
        }}>
          <Typography
            style={{
              fontSize: '1.3rem'
            }}
            variant="subtitle1" component="h2">
            Assessment Test
          </Typography>
        </Box>
        <Container
          style={{
            minHeight: "100vh",
            paddingTop: "1rem",
            padding: "2rem",
          }}
        >
          {this.renderTab()}
          {this.renderAssessmentList()}
          {this.renderAttemptedAssessment()}
        </Container>
      </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.renderContent()}
        {this.renderAssessmentTestWebError()}
        {this.renderAssessmentTestWebLoader()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webstyles = {
  navBar: {
    position: "relative",
    padding: "12px 64px",
    background: "rgb(37, 0, 65)",
    color: "rgb(255, 255, 255)",
  },

  card: {
    position: "relative",
    overflow: "hidden",
    background: "linear-gradient(60deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.6))",
    borderRadius: "8px",
    color: "rgb(255, 255, 255)",
    boxShadow: "5px 5px 15px rgb(43, 43, 43)",
  }
}

// Customizable Area End
