export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backarrow = require("../assets/backarrow.png");
export const cardblank = require("../assets/cardblank.png");
export const fillstar = require("../assets/fillstar.png");
export const empty = require("../assets/empty.png");
export const headerLogo = require("../assets/image_logo.png");
export const sponcer = require("../assets/sponsor.png");
export const userLogo = require("../assets/headerUser.svg");
export const NoStudyFoundLogo = require("../assets/NoStudyImg.png");
export const language = require("../assets/language.png");