import React from "react";

// Customizable Area Start
import {
    Box,
    BoxProps,
    Grid,
    Typography,
    Divider,
    styled,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
  } from "@material-ui/core";
  import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
  import { Milestone, progressSide, sideImage } from "./assets";
  import { EventNote } from "@material-ui/icons";

// Customizable Area End

import DashboardController, {
    Props
} from "./DashboardController";
export default class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    // Customizable Area Start
    render() {
        const BoxView = Box as React.FC<BoxProps>;
        return (
          <>
          <p id="topScroll" style={{top:"-100",position:"absolute",left:"0",width:"100px",height:"100px"}} >Hi</p>
            <ModalStyle>
              <Grid xs={10} item>
                <BoxView className="subGrid">
                  <Typography className="mainPatients">{this.state.translations.Dashboard}</Typography>
                  <Typography className="subText">
                   {this.state.translations.DashboardDescription}
                  </Typography>
                  <Typography className="currentMilestone">
                    {this.state.translations.Current_Milestone}
                  </Typography>
                  <Divider className="divider" />
                  <BoxView className="listItemBoxWrap">
                    <BoxView
                      className="listItemone"
                      style={{ position: "relative" }}
                    >
                      <BoxView
                        className="listbox"
                        style={{
                          position: "relative",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={progressSide}
                          style={{ position: "absolute", right: "0", top: "0" }}
                        />
                        <Box
                          position="relative"
                          style={{ left: "-20", top: "-35" }}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={100}
                            size={150}
                            thickness={4}
                            style={{
                              color: "rgba(0,0,0,0.21",
                              position: "absolute",
                              zIndex: "0",
                            }}
                          />
                          <CircularProgress
                            variant="determinate"
                            value={this.state.milestoneData.milestones.progress}
                            size={150}
                            thickness={4}
                            style={{
                              color: "white",
                              zIndex: "1",
                              strokeLinecap: "round",
                            }}
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            zIndex={3}
                          >
                            <Typography
                              variant="caption"
                              style={{
                                color: "white",
                                fontFamily: "Jost",
                                fontWeight: "700",
                                fontSize: "30px",
                              }}
                            >
                              {this.state.milestoneData.milestones.progress}%
                            </Typography>
                          </Box>
                        </Box>
                      </BoxView>
                    </BoxView>
                    <BoxView className="listItemtwo">
                      <BoxView className="listbox">
                        <Typography className="total">
                          {this.state.milestoneData.milestones.total_milestone}
                        </Typography>
                        <BoxView style={{ display: "flex", gap: "40px" }}>
                          <BoxView>
                            <Typography className="totalDashbord">{this.state.translations.Total}</Typography>
                            <Typography className="milestone">{this.state.translations.Milestone}</Typography>
                          </BoxView>
                          <BoxView>
                            <img src={Milestone} height={40} width={40} />
                          </BoxView>
                        </BoxView>
                      </BoxView>
                    </BoxView>
    
                    <BoxView className="listItemtwo">
                      <BoxView className="listbox">
                        <Typography className="total">
                          {this.state.milestoneData.milestones.current_milestone}
                        </Typography>
                        <BoxView style={{ display: "flex", gap: "40px" }}>
                          <BoxView>
                            <Typography className="totalDashbord">
                              {this.state.translations.Current}
                            </Typography>
                            <Typography className="milestone">{this.state.translations.Milestone}</Typography>
                          </BoxView>
                          <BoxView>
                            <img src={Milestone} height={40} width={40} />
                          </BoxView>
                        </BoxView>
                      </BoxView>
                    </BoxView>
                  </BoxView>
    
                  <Typography className="MilestoneDetails">
                   {this.state.translations.Milestone_Details}
                  </Typography>
                  <div>
                    {this.state.milestones.map((milestone: any) => (
                      <Accordion
                        id="MilestonesAccordion"
                        key={milestone.id}
                        className="accordion"
                        defaultExpanded
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="Milestone1">
                            {milestone.attributes ? milestone.attributes.name : ""}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: milestone.attributes?.milestone_description,
                            }}
                          />
                        </AccordionDetails>
                        <Divider style={{ 
                          margin: "0px 20px" 
                          }} />
                        <AccordionDetails>
                          <BoxView className="accordionDetails">
                            <BoxView className="subDetails">
                              <BoxView className="boxDetails">
                                <Typography
                                  className="accordionDetailes"
                                  style={{ 
                                    marginRight: "68px" 
                                  }}
                                >
                                  {this.state.translations.Points} :
                                </Typography>
                                <Typography className="accordionSubDetailes">
                                {milestone.attributes.total_points } {this.state.translations.Points}
                                </Typography>
                              </BoxView>
                              <BoxView 
                                className="boxDetails"
                                >
                                <Typography className="accordionDetailes">
                                  {this.state.translations.Duration} :
                                </Typography>
                                <Typography className="accordionSubDetailes">
                                  {this.calculateDuration(
                                    milestone.attributes?.start_date,
                                    milestone.attributes?.end_date
                                  )}
                                </Typography>
                              </BoxView>
                            </BoxView>
                            <BoxView className="accordionDetailesTwo">
                              <BoxView className="boxDetails">
                                <Typography className="accordionDetailes">
                                  {this.state.translations.Start_Date} :
                                </Typography>
                                <Typography className="accordionSubDetailes">
                                  <EventNote style={{ marginRight: "10px" }} />{" "}
                                  {this.formatDate(
                                    milestone.attributes?.start_date
                                  )}
                                </Typography>
                              </BoxView>
                              <BoxView className="boxDetails">
                                <Typography className="accordionDetailes">
                                  {this.state.translations.End_Date}
                                </Typography>
                                <Typography className="accordionSubDetailes">
                                  <EventNote style={{ marginRight: "10px" }} />{" "}
                                  {this.formatDate(milestone.attributes?.end_date)}
                                </Typography>
                              </BoxView>
                            </BoxView>
                          </BoxView>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                  <Typography className="MilestoneDetails" style={{width:"900px"}}>
                    {this.state.studyName}
                  </Typography>
                  <Divider className="divider" />
                  <BoxView className="vaccination">
                    <BoxView className="boxDetails">
                      <Typography className="studyNumber">{this.state.translations.Study_Number}:</Typography>
                      <Typography className="accordionSubDetailes">
                        {this.state.studyNumber}
                      </Typography>
                    </BoxView>
                    <BoxView className="boxDetails">
                      <Typography className="accordionDetailes">
                        {this.state.translations.Total} {" "} {this.state.translations.Milestone}:
                      </Typography>
                      <Typography className="accordionSubDetailes">
                        {this.formatNumber(this.state.milestones.length)}
                      </Typography>
                    </BoxView>
                    <BoxView className="boxDetails">
                      <Typography className="accordionDetailes">
                        {this.state.translations.Duration}:
                      </Typography>
                      <Typography className="accordionSubDetailes">
                        {this.calculateTotalDuration(this.state.milestones)}
                      </Typography>
                    </BoxView>
                  </BoxView>
                  <BoxView className="listItemBoxWrap">
                    <BoxView className="subBox">
                      <BoxView className="listboxTwo">
                        <img src={sideImage} width={153} height={153} />
                        <BoxView>
                          <Typography className="detailOfTrail">
                            {this.state.translations.DetailOf} {this.state.studyName}
                          </Typography>
                          <Typography className="detailOfTrailText">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.studyDescription,
                              }}
                            />
                          </Typography>
                        </BoxView>
                      </BoxView>
                    </BoxView>
                  </BoxView>
                  <Typography className="MilestoneDetails">{this.state.translations.Site_Details}</Typography>
                  <div>
                    {this.state.sites.map((site, index) => (
                      <Accordion
                        key={site.id}
                        className="accordion"
                        id="sitesAccordion"
                        defaultExpanded
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index + 1}-content`}
                          id={`panel${index + 1}-header`}
                        >
                          <Typography className="site1">
                            {this.state.translations.Site} {index + 1}: {site.attributes?.site_name}
                          </Typography>
                        </AccordionSummary>
    
                        <Divider style={{ margin: "0px 20px" }} />
                        <AccordionDetails>
                          <BoxView className="accordionDetails">
                            <BoxView className="subDetails">
                              <BoxView className="boxDetails">
                                <Typography className="accordionDetailes">
                                 {this.state.translations.Site_ID}:
                                </Typography>
                                <Typography className="accordionSubDetailes">
                                  {site?.id}
                                </Typography>
                              </BoxView>
                              <BoxView className="boxDetails">
                                <Typography className="accordionDetailes">
                                  {this.state.translations.Site_Coordinator}:
                                </Typography>
                                <Typography className="accordionSubDetailes" id={`SC${index}`}>
                                  {site?.attributes.site_coordinator_name}
                                </Typography>
                              </BoxView>
                              <BoxView className="boxDetails">
                                <Typography className="accordionDetailes">
                                {this.state.translations.Site_Country}
                                </Typography>
                                <Typography className="accordionSubDetailes">
                                  {site.attributes?.site_country}
                                </Typography>
                              </BoxView>
                            </BoxView>
                            <BoxView className="accordionDetailesTwo">
                              <BoxView className="boxDetails">
                                <Typography className="accordionDetailes">
                                {this.state.translations.Site_Address}
                                </Typography>
                                <Typography className="accordionSubDetailes">
                                  {site.attributes?.site_address}
                                </Typography>
                              </BoxView>
                            </BoxView>
                          </BoxView>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </BoxView>
              </Grid>
            </ModalStyle>
          </>
        );
      }
    // Customizable Area End
}
// Customizable Area Start
const ModalStyle = styled(Box)({
    "& .mainPatients": {
      color: "#1F3650",
      fontFamily: "Jost",
      fontSize: "40px",
      fontWeight: 700,
    },
    "& .subGrid": {
      margin: "40px",
    },
    "& .subText": {
      color: "#414141",
      fontFamily: "Jost",
      fontSize: "14px",
      width : "900px",
    },
    "& .listItemone": {
      backgroundColor: "#A046FB",
      marginTop: "30px",
      width: "440px",
      height: "219px",
      borderRadius: "8px",
      color: "#fff",
    },
    "& .listbox": {
      padding: "40px 25px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    "& .total": {
      color: "#4A4949",
      fontFamily: "Jost",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 600,
    },
    "& .totalDashbord": {
      color: "#434343",
      fontFamily: "Jost",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "30px",
    },
    "& .listItemBoxWrap": {
      display: "flex",
    },
    "& .currentMilestone": {
      color: "#292929",
      fontFamily: "Jost",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "34px",
      marginTop: 30,
    },
    "& .divider": {
      marginTop: 15,
      maxWidth: "900px",
    },
    "& .listitem": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .listItemtwo": {
      backgroundColor: "#F0F0F0",
      marginTop: "30px",
      marginLeft: "25px",
      width: "206px",
      height: "219px",
      borderRadius: "8px",
      color: "#1F3650",
    },
    "& .listItemthree": {
      backgroundColor: "#F0F0F0",
      marginTop: "30px",
      marginLeft: "25px",
      width: "206px",
      height: "219px",
      borderRadius: "8px",
      color: "#1F3650",
    },
    "& .milestone": {
      color: "#434343",
      fontFamily: "Jost",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "26px",
    },
    "& .MilestoneDetails": {
      color: "#292929",
      fontFamily: "Jost",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "34px",
      marginTop: 30,
      width:"65%"
    },
    "& .Milestone1": {
      fontFamily: "Jost",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "26px",
    },
    "& .accordionDetailes": {
      fontFamily: "Jost",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "26px",
      marginRight: "20px",
    },
    "& .accordionSubDetailes": {
      fontFamily: "Jost",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "26px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .accordion": {
      marginTop: "16px",
      backgroundColor: "#f0f0f0",
      boxShadow: "none",
      borderRadius: "8px",
      maxWidth: "900px",
    },
    "& .accordionDetails": {
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      marginTop: "15px",
    },
    "& .subDetails": {
      display: "flex",
      gap: "150px",
    },
    "& .boxDetails": {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    },
    "& .accordionDetailesTwo": {
      display: "flex",
      gap: "120px",
    },
    "& .vaccination": {
      display: "flex",
      gap: "125px",
      marginTop: "30px",
    },
    "& .studyNumber": {
      color: "#292929",
      fontFamily: "Jost",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "26px",
    },
    "& .subBox": {
      backgroundColor: "#F0F0F0",
      marginTop: "30px",
      width: "910px",
      maxHeight: "fit-content",
      borderRadius: "8px",
    },
    "& .listboxTwo": {
      padding: "25px",
      display: "flex",
      gap: "50px",
    },
    "& .detailOfTrail": {
      color: "#292929",
      fontFamily: "Jost",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "34px",
      marginBottom: "12px",
    },
    "& .detailOfTrailText": {
      color: "#1F0603",
      fontFamily: "Jost",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "30px",
    },
    "& .site1": {
      color: "#1F3650",
      fontFamily: "Jost",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "26px",
    },
  });
// Customizable Area End