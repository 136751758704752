import React from "react";
// Customizable Area Start
import { Accordion, AccordionDetails, Typography, AccordionSummary, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, Grid, Hidden, IconButton, InputAdornment, Paper, TextField, styled, BoxProps, DrawerProps, Snackbar, Modal, MenuItem, ClickAwayListener } from '@material-ui/core';
import SideNavbarController, {
    Props, configJSON
} from "./SideNavbarController";
import { buttonCancel, headerLogo, languageLogo, lockLogo, rightLogo, sponcer } from "../../email-account-registration/src/assets";
import { callLogo, contactEmailLogo, contactPhoneLogo, dropdownLogo, editLogo, emailLogo, humanLogo, locationLogo, rightArrow, userLogo, selectedLogo } from "../../customisableuserprofiles/src/assets";
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PhoneInput from 'react-phone-input-2'
import { language } from "./assets";

// Customizable Area End

 class SideNavbar extends SideNavbarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    drawerStudy = () => {
        const { studyDrawerOpen, searchQuery, languageSwitch } = this.state
        const filteredStudies = this.getFilteredStudies();
        const BoxView = Box as React.FC<BoxProps>
        return (
                 <Modal
                    open={studyDrawerOpen}
                    onClose={this.handleStudyDrawerOpen}
                >
                    <StudyModalStyle>
                        <div className="containerStudy">
                            <Grid item xs={12}>
                                <BoxView className="searchStudy">
                                    <TextField
                                        value={searchQuery}
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Search Study Name"
                                        onChange={this.handleSearchChange}
                                        id="searchTextField"
                                    />
                                </BoxView>
                                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                    {filteredStudies?.map((item: any) => (
                                        <BoxView key={item.id}>
                                            <Typography
                                                id={`studyVal-${item.id}`}
                                                className={`studyName ${this.state.selectedStudy === item.id ? 'selected' : ''}`}
                                                onClick={() => this.handleItemClick(item)}
                                            >
                                                {item.attributes.study_name}
                                                {typeof window !== 'undefined' &&
                                                    window.localStorage.getItem('studyNumber') === item.id && (
                                                        <img src={selectedLogo} alt="selectedLogo" className="selectLogo" />
                                                    )}
                                            </Typography>
                                        </BoxView>
                                    ))}
                                    {filteredStudies?.length === 0 && (
                                        <BoxView>
                                            <Typography id="studySearch" className="studyName" style={{ cursor: 'none' }}>
                                             {languageSwitch.NoMatchFound}
                                            </Typography>
                                        </BoxView>
                                    )}
                                </div>
                            </Grid>
                        </div>
                    </StudyModalStyle>
                </Modal>
        )
    }
    passwordDialog = () => {
        const { oldPassword, newPassword, newConfirmPassword, oldPasswordError, newPasswordError, newConfirmPasswordError, languageSwitch } = this.state;

        return (
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={this.state.passwordDialogOpen}
                aria-labelledby="responsive-dialog-title" >
                <PasswordDialogStyle>
                    <DialogActions>
                        <IconButton id="cancel-password" data-test-id="cancel-password" onClick={() => this.handleClose("Change Password")} >
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className="dialog-title">{languageSwitch.ChangePassword}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography className="dialog-para">
                            {languageSwitch.ChangePasswordMsg}
                        </Typography>
                        <Grid item xs={12}>
                            <TextField
                                type={this.state.showOldPassword ? 'text' : 'password'}
                                fullWidth
                                id="oldPassword"
                                variant="outlined"
                                placeholder={languageSwitch.EnterOldPassword}
                                value={oldPassword}
                                onChange={(events) => { this.handleOldPasswordChange(events.target.value) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {oldPassword.length > 0 ? (<IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowOldPassword()}
                                                data-test-id="toggleButton"
                                           >
                                                {!this.state.showOldPassword ? <VisibilityOff /> : <Visibility /> }
                                            </IconButton>) : null}
                                        </InputAdornment>
                                    ),
                                    className: "oldPsswd"
                                }}
                                inputProps={{
                                    "data-test-id": 'oldPassword'
                                }}
                                helperText={oldPasswordError}
                                error={oldPasswordError !== ''}
                                
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type={!this.state.showNewPassword ? 'password' : 'text' }
                                fullWidth     
                                id="newPsswd"
                                placeholder={languageSwitch.EnterNewPassword}
                                value={newPassword}
                                variant="outlined"
                                onChange={(event) => this.handleNewPasswordChange(event.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {newPassword.length > 0 ? (<IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowNewPassword()}
                                            >
                                                {!this.state.showNewPassword ? <VisibilityOff /> : <Visibility /> }
                                            </IconButton>) : null}
                                        </InputAdornment>
                                    ),
                                    className: "newPsswd"

                                }}
                                inputProps={{
                                    "data-test-id": 'newPassword'
                                }}
                                error={newPasswordError !== ''}
                            />
                            <Typography className="passwordError">{this.state.newPasswordError}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type={!this.state.showNewConfirmPassword ? 'password' : 'text'}
                                fullWidth
                                id="cnewPsswd"
                                onChange={(events) => this.handleNewConfirmPasswordChange(events.target.value)}
                                variant="outlined"
                                value={newConfirmPassword}
                                placeholder={languageSwitch.ConfirmNewPassword}
                                
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {newConfirmPassword.length > 0 ? (<IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowNewConfirmPassword()}
                                            >
                                                {!this.state.showNewConfirmPassword ? <VisibilityOff /> : <Visibility /> }
                                            </IconButton>) : null}
                                        </InputAdornment>
                                    ),
                                    className: "cnewPsswd"
                                }}
                                inputProps={{
                                    "data-test-id": 'confirmNewPassword'
                                }}
                                helperText={newConfirmPasswordError}
                                error={newConfirmPasswordError !== ''}
                               
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" className="change-password-btn"
                                fullWidth
                                onClick={() => this.newSubmitPassWord()}
                                type='reset'
                                data-test-id="update-password"
                                id="update-password"
                            >{languageSwitch.UpdatePassword}</Button>
                        </Grid>
                    </DialogContent>

                </PasswordDialogStyle>
            </Dialog>
        )
    }

    mailDialog = () => {
        const strongEmailRegex = configJSON.StrongemailRegex;
        const { oldEmailAddress, newEmailAddress, newConfirmEmailAddress, oldEmailAddressError, newEmailAddressError, newConfirmEmailAddressError, languageSwitch } = this.state
        return (
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={this.state.requestAdmin}
                aria-labelledby="responsive-dialog-title"
            >
                <MailDialogStyle>
                    <DialogActions>
                        <IconButton id='cancel-mailBtn' onClick={() => this.handleClose("Request Admin")}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className="dialog-title">{languageSwitch.ChangeEmailRequest}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography className="dialog-para">
                            {languageSwitch.ChangeEmailReqMsg}
                        </Typography>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="oldEmailAddress"
                                onChange={(event) => { this.handleOldEmailAddress(event.target.value) }}
                                variant="outlined"
                                value={oldEmailAddress}
                                placeholder={languageSwitch.EnterOldEmail}
    
                                
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={emailLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {strongEmailRegex.test(oldEmailAddress) ? (<img src={rightLogo} />):null}
                                        </InputAdornment>
                                    ),
                                    className: "oldEmail"
                                }}
                                inputProps={{
                                    'data-test-id': "oldEmail"
                                }}
                                helperText={oldEmailAddressError}
                                error={oldEmailAddressError !== ''}
                                
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="newEmailAddress"
                                value={newEmailAddress}
                                
                                
                                onChange={(event) => this.handleNewEmailAddress(event.target.value)}
                                placeholder={languageSwitch.EnterNewEmail}
                                helperText={newEmailAddressError}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={emailLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {strongEmailRegex.test(newEmailAddress) ? <img src={rightLogo} />: null}
                                        </InputAdornment>
                                    ),
                                    className: "newEmail",
                                }}
                                inputProps={{
                                    'data-test-id': "newEmail"
                                }}
                                error={newEmailAddressError !== ''}
                                
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="newConfirmEmailAddress"
                                variant="outlined"
                                placeholder={languageSwitch.ConfirmNewEmail}
                               
                                value={newConfirmEmailAddress}
                                helperText={newConfirmEmailAddressError}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={emailLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                           {strongEmailRegex.test(newConfirmEmailAddress) ? <img src={rightLogo} />:null}
                                        </InputAdornment>
                                    ),
                                    className: "cNewEmail"
                                }}
                                inputProps={{
                                    'data-test-id': "newConfirmEmail"
                                }}
                                error={newConfirmEmailAddressError !== ''}
                                
                                onChange={(event) => this.handleNewConfirmEmailAddress(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" className="change-password-btn"
                                fullWidth
                                id="send-request"
                                data-test-id="send-request"
                                onClick={() => this.newSubmitEmailAddress()}
                            >{languageSwitch.SendRequest}</Button>
                        </Grid>
                    </DialogContent>

                </MailDialogStyle>
            </Dialog>
        )
    }

    drawerMain = () => {
        const BoxView = Box as React.FC<BoxProps>
        const { userDetails, languageSwitch } = this.state
        return (
            <MainStyle>
                 {this.state.showToast && <Snackbar id="snackbar-id" anchorOrigin={{vertical:"top", horizontal:"center"}} key={"top" + "right"} message="Password updated Successfully! Try logging in with new password." open={this.state.showToast}  />  }
                <Container className="mainContainer" >
                    <Typography component={"span"} className="profile">{languageSwitch.Profile}</Typography>
                    <IconButton className="editLogo" id="editLogo" data-test-id="editLogo" onClick={this.handleEditLogoClick}>
                        <img src={editLogo} alt="editLogo" style={{borderRadius:"50%"}} />
                    </IconButton>
                    <Typography className="userGroup">
                        <Typography>
                            <BoxView className="box">
                                <img src={this.state.profilePicture ? this.state.profilePicture : userLogo} alt="userLogo" style={{ verticalAlign: 'top' ,width:"50px",height:"50px",borderRadius:"50%"}} />
                                <BoxView className="nameGrp">
                                    <Typography className="userName" component={'span'}>{userDetails?.full_name}</Typography>
                                    <Typography className="userMail" component={'div'}>{userDetails?.email}</Typography>
                                </BoxView>
                            </BoxView>
                        </Typography>
                    </Typography>
                    <Divider />


                    <Typography className="newFullNameGroup">
                        <BoxView className="box">
                            <img src={humanLogo} alt="humanLogo" className="humanLogo" />
                            <BoxView>
                                <Typography className="labelName">{languageSwitch.FullName}</Typography>
                                <Typography className="valueName">{userDetails?.full_name}</Typography>
                            </BoxView>
                        </BoxView>
                    </Typography>

                    <Typography className="newEmailGroup">
                        <BoxView className="box">
                            <img src={emailLogo} alt="emailLogo" className="emailLogo" />
                            <BoxView>
                                <Typography className="labelName">{languageSwitch.Email}</Typography>
                                <Typography className="valueName">{userDetails?.email}</Typography>
                            </BoxView>
                        </BoxView>
                    </Typography>

                    <Typography className="newPhoneGroup">
                        <BoxView className="box">
                            <img src={callLogo} alt="callLogo" className="callLogo" />
                            <BoxView>
                                <Typography className="labelName">{languageSwitch.Phone}</Typography>
                                <PhoneInput  value={userDetails?.full_phone_number} country={'us'} disabled={true} />
                            </BoxView>
                        </BoxView>
                    </Typography>

                    <Typography className="newAddressGroup">
                        <BoxView className="box">
                            <img src={locationLogo} alt="locationLogo" className="locationLogo" />
                            <BoxView style={{ maxWidth: '300px' }}>
                                <Typography className="labelName">{languageSwitch.Address}</Typography>
                                <Typography className="valueName" style={{wordWrap: 'break-word' }}>{this.state.completeAddress}</Typography>
                            </BoxView>
                        </BoxView>
                    </Typography>

                    <Divider />
                    <>
                        {this.state.detail.map((element: any, index: number) => (
                            <>
                                <BoxView className="listGroup">
                                    <img src={element.dImg} alt="d-image-logo" className="listLogo" />
                                    <Typography component={"span"} className="listName">{element.dName}</Typography>
                                    <img src={rightArrow} alt="rightArrow" className="rightLogo" data-test-id={element.id} onClick={() => this.handleClickOpen(element.dName)} />
                                    <Divider className="divider" />
                                </BoxView>
                            </>
                        ))}
                    </>


                </Container>
            </MainStyle>
        )
    }

    technicalHelp = () => {
        const { languageSwitch } = this.state

        return (
            <HelpModalStyle>
                <Container className="helpContainer">
                    <IconButton className="cancelBtn" data-test-id="cancel-tech" onClick={() => this.handleClose("Technical Help")}>
                        <img src={buttonCancel} alt="buttonCancel" />
                    </IconButton>
                    <Typography component={"span"} className="editProfile">{languageSwitch.TechnicalHelp}</Typography>
                    <Paper style={{ paddingTop: '20px' }}>
                        {this.state.technicalHelpData?.map((item: any) => (
                            <React.Fragment key={item.id}>
                                <Accordion expanded={this.state.expanded === item.id} onChange={this.handleChange(item.id)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id={item.id}
                                    >
                                        <Typography className="header">{item.attributes.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className="para">
                                            {item.attributes.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Divider style={{ width: '94%', background: "#EBEBEB" }} />

                            </React.Fragment>
                        ))}
                    </Paper>

                </Container>
            </HelpModalStyle>
        )
    }

    drawerEdit = () => {
        const { userEmail, userEmailError, phoneNumber, phoneNumberError, fullName, fullNameError, userDetails, addressLine1, addressLine2, addressLine3, languageSwitch } = this.state
        const BoxView = Box as React.FC<BoxProps>
        return (
            <EditModalStyle>
                <Container className="mainEditContainer">
                    <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                    <Typography component={"span"} id="editProfile" className="editProfile">{languageSwitch.EditProfile}</Typography>
                    <DialogActions>
                        <IconButton id='cancel-mailBtnEdit' onClick={this.handleEditProfileClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    </div>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} marginY={4}>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <img src={this.state.profilePictureEdit ? this.state.profilePictureEdit : userLogo } alt="userLogo" style={{ height:"50px",width:"50px", marginRight: 8, borderRadius:"50%" }} />
                            <Typography className="userEditName" component={'span'}>{userDetails?.full_name}</Typography>
                        </Box>
                        <div style={{display:"flex",flexDirection:"column"}}>
                        <BoxView className="nameEditGrp">
                                    <label htmlFor="profile-img" style={{ width: "190px",cursor:"pointer" }}><span className="changeProBtn">{languageSwitch.ChangeProfilePicture}</span></label>
                                    <input id="profile-img" type="file" accept=".png,.jpg" style={{ display: "none" }}  onChange={(event)=>this.handleProfilePictureChange(event)}
                                    />
                                   
                                </BoxView>
                          {this.state.profilePicError &&  <span id="profilePicErrorId" style={{color:"red",fontSize:'12px', marginTop:'1rem'}} >{languageSwitch.ImageUploadType}</span> }
                                </div>
                    </Box>
                    
                    <BoxView>
                        <Typography className="labelName">{languageSwitch.FullName}</Typography>
                        <TextField
                            id="fullName"
                            className="fullNameEdit"
                            onChange={this.handleFullNameEdit}
                            value={fullName}
                            error={fullNameError !== ''}
                            inputProps={{ "data-test-id": "fullName-edit" }} />
                        <p className="errorText">{fullNameError}</p>
                    </BoxView>
                    <BoxView className="boxStyle">
                        <Typography className="labelName">{languageSwitch.EmailAddress}</Typography>
                        <TextField
                            disabled
                            className="fullNameEdit"
                            id="userEmail"
                            value={userEmail}
                            onChange={this.handleUserNewEmail}
                            error={userEmailError !== ''}
                            inputProps={{ "data-test-id": "email-edit" }}
                        />
                        <p className="errorText">{userEmailError}</p>
                        <Typography className="request" data-test-id="request-admin" onClick={() => this.handleClickOpen('Request Admin')}>Request Admin</Typography>
                    </BoxView>
                    <BoxView className="boxStyle">
                        <Typography className="labelName">{languageSwitch.PhoneNumber}</Typography>
                        <PhoneInput country={'us'}  inputProps={{ "data-test-id": "phoneNumber-edit1","id":"phoneNumberEdit1" }} inputStyle={{width:"350px",borderRadius: "67px",height:"42px"}} value={phoneNumber} onChange={(event) => this.handlePhoneNumberChangeNew(event)}
                           
                           />
                        <p className="errorText">{phoneNumberError}</p>

                    </BoxView>
                    <BoxView className="boxStyle">
                        <Typography className="labelName">{languageSwitch.Address}</Typography>
                        <BoxView>
                            <TextField placeholder="Address Line 1" id="addressLine1id" className="fullNameEdit" value={addressLine1} onChange={this.handleAddressLine1Change}/>
                        </BoxView>
                        <BoxView className="boxStyle">
                            <TextField placeholder="Address Line 2" id="addressLine2id" value={addressLine2} className="fullNameEdit"  onChange={this.handleAddressLine2Change}/>
                        </BoxView>
                        <BoxView className="boxStyle">
                            <TextField placeholder="Address Line 3" id="addressLine3id" value={addressLine3} className="fullNameEdit" onChange={this.handleAddressLine3Change}/>
                        </BoxView>
                    </BoxView>
                    <Button
                        data-test-id="update-detail"
                        variant="outlined"
                        onClick={this.handleUpdateDetailsClick}
                        className="updatebtn"
                        fullWidth
                       
                    >{languageSwitch.UpdateDetails}</Button>
                </Container>
            </EditModalStyle>
        )
    }

    drawerContact = () => {
        const { selectError, selectedReason, languageSwitch, contactFullName, contactFullNameError, contactUserEmailError, contactUserEmail, contactUserMessage } = this.state
        const BoxView = Box as React.FC<BoxProps>
        return (
            <ContactModalStyle>
                <Container className="contactContainer">
                    <img src={buttonCancel} alt="YourbuttonCancel" id="cancel-contact" className="cancelBtn" onClick={() => this.handleClose("Contact Us")} />
                    <Typography component={"span"} className="contactUs" id="contact-us-heading" >{languageSwitch.ContactUs}</Typography>

                    <Typography className="talkText">{languageSwitch.TalkCustomerSupport}</Typography>
                    <Typography className="talkPara">{languageSwitch.TalkCustomerSupportDetails}</Typography>

                    <Typography className="cntGrp">

                        <BoxView className="box">
                            <img src={contactPhoneLogo} alt="contactPhoneLogo" style={{ paddingTop: '10px' }} />
                            <BoxView className="phneGroup">
                                <Typography className="phone" component={'span'}>{languageSwitch.Phone}</Typography>
                                <Typography className="pNumber" component={'div'}>{languageSwitch.phoneNumberDigits}</Typography>
                                <Typography className="availTime">{languageSwitch.AvailableTime}</Typography>
                            </BoxView>
                        </BoxView>

                        <BoxView className="box">
                            <img src={contactEmailLogo} alt="contactEmailLogo" style={{ paddingTop: '10px' }} />
                            <BoxView className="emailGrp">
                                <Typography className="emailTxt" component={'span'}>{languageSwitch.Email}</Typography>
                                <Typography className="supportEmail" component={'div'}>supportemail@gmail.com</Typography>
                            </BoxView>
                        </BoxView>
                        <Typography className="sendText">{languageSwitch.SendYourQueries}</Typography>
                    </Typography>
                    <>
                        <BoxView className="boxStyle">
                            <Typography className="cntLabelName">{languageSwitch.FullName}</Typography>
                            <TextField placeholder="John"
                                className="cntFullNameEdit"
                                onChange={this.handleContactFullName}
                                error={contactFullNameError !== ''}
                                inputProps={{
                                    "data-test-id": 'contact-fullname'
                                }}
                                id="contactFullName"
                                value={contactFullName}
                               
                               
                            />
                            <p className="errorText">{contactFullNameError}</p>
                        </BoxView>
                        <BoxView className="boxStyle">
                            <Typography className="cntLabelName">{languageSwitch.EmailAddress}</Typography>
                            <TextField
                                value={contactUserEmail}
                                placeholder="johndeo@gmail.com"
                                error={contactUserEmailError !== ''}
                                className="cntFullNameEdit"
                                inputProps={{
                                    "data-test-id": 'contact-email'
                                }}
                                onChange={this.handleContactUserEmail}
                                
                                
                            />
                            <p className="errorText">{contactUserEmailError}</p>
                        </BoxView>

                        <BoxView className="boxStyle">
                            <Typography className="cntLabelName">{languageSwitch.Reason}</Typography>
                            
                                 <TextField
                                value={selectedReason}
                                placeholder={languageSwitch.ReasonPlaceholder}
                                error={selectError !== ''}
                                className="cntFullNameEdit"
                                inputProps={{
                                    "data-test-id": 'contact-email'
                                }}
                                onChange={this.handleDropdownChange}
                                
                                
                            />
                
                            <p className="errorText">{selectError}</p>
                        </BoxView>

                        <BoxView className="boxStyle">
                            <Typography className="cntLabelName">{languageSwitch.Message}</Typography>
                            <TextField
                                placeholder={languageSwitch.TypeYourMsg}
                                onChange={this.handleContactUserMessageChange}
                                className="cntFullNameEdit"
                                value={contactUserMessage}
                                
                            />
                        </BoxView>

                        <Button className="submitCntBtn" fullWidth
                         onClick={this.handleContactSubmitButton}
                            data-test-id="contactSubmitBtn"
                           
                        >{languageSwitch.Submit}</Button>
                    </>
                </Container>
            </ContactModalStyle>
        )
    }

    termsDialog = () => {
        const { termsAndConditions, languageSwitch } = this.state;
        const BoxView = Box as React.FC<BoxProps>
        return (
            <Dialog
                scroll="body"
                className="termContainer"
                fullWidth
                open={this.state.termsDialogOpen}
                maxWidth={'lg'}
                aria-labelledby="responsive-dialog-title" >
                <TermsDialogStyle>
                    <DialogActions>
                        <IconButton id="cancel-terms" onClick={() => this.handleClose("Terms and Conditions")}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className='termsText'>{languageSwitch.TandC}</Typography>
                    </DialogTitle>
                    <DialogContent >
                        <BoxView className="box">
                            <div className="dialogContent" dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
                        </BoxView>
                    </DialogContent>
                </TermsDialogStyle>
            </Dialog>
        )
    }

    privacyDialog = () => {
        const { privacyPolicy, languageSwitch } = this.state
        const BoxView = Box as React.FC<BoxProps>
        return (
            <Dialog
                scroll="body"
                maxWidth={'lg'}
                className="privacyContainer"
                fullWidth
                open={this.state.privacyDialogOpen}
                aria-labelledby="responsive-dialog-title" >
                <PrivacyDialogStyle>
                    <DialogActions>
                        <IconButton id="cancel-privacy" onClick={() => this.handleClose("Privacy Policies")}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className='privacyText'>{languageSwitch.PrivacyPolicy}</Typography>
                    </DialogTitle>
                    <DialogContent >
                        <BoxView className="box">
                            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} className="dialogContent"  />
                        </BoxView>
                    </DialogContent>
                </PrivacyDialogStyle>
            </Dialog>
        )
    }
    logoutDailog = () => {
        const { languageSwitch } = this.state;

        return (

            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={this.state.logoutFlag}
                aria-labelledby="responsive-dialog-title" >
                <PasswordDialogStyle>
                    <DialogActions>
                        <IconButton data-test-id="cancel-passwordlogout"
                            onClick={this.handleLogoutFlag}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className="dialog-title">{languageSwitch.Logout}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography className="dialog-para">
                            {languageSwitch.LogoutMsg}
                        </Typography></DialogContent>
                    <div style={{ display: 'flex', margin: '20px', justifyContent: "space-between", }}>
                        <Button variant="outlined" className="change-password-btn"
                            style={{ width: '100%', margin: '30px' }}
                            onClick={() => this.handleLogout()}
                            type='reset'
                            data-test-id="deleteschedule"
                        >{languageSwitch.Yes}</Button>
                        <Button variant="outlined" className="change-password-btn"
                            style={{ width: '100%', margin: '30px' }}
                            onClick={this.handleLogoutFlag}
                            type='reset'
                            data-test-id="cancelschedule"
                        >{languageSwitch.No}</Button></div></PasswordDialogStyle>
            </Dialog>

        )
    }

    sessionExpiredModal = () => {
        const { languageSwitch } = this.state;

        return (
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={this.state.sessionDilogue || this.props.sessionDilogue}
                aria-labelledby="responsive-dialog-title" >
                <PasswordDialogStyle>
                    <DialogTitle style={{ fontSize: '18px', fontWeight: 600, marginTop: 40,textAlign: 'center' }}>
                        {languageSwitch.SessionExpiredMsg1},<br/> {languageSwitch.SessionExpiredMsg2}
                    </DialogTitle>
                    <div style={{ display: 'flex', margin: '20px', justifyContent: "space-between", }}>
                        <Button variant="outlined" className="change-password-btn"
                            style={{ width: '100%', margin: '30px' }}
                            onClick={() => this.navigateToLogin()}
                            type='reset'
                            data-test-id="sessionExpired"
                        >{languageSwitch.Okay}</Button>
                        </div></PasswordDialogStyle>
            </Dialog>

        )
    }


    languageDropdown = ()=>{
        const languages : any = {
            en: 'English',
            es: 'Spanish',
            fr: 'French',
            de: 'German',
          };
        return (
            <LanguageDropdownStyle>
            <div id="langDropdown" style={{ position: 'relative', display: 'inline-block', borderRadius : "8px" }}>
            <IconButton className="languageSwitchButton" onClick={this.handleLanguageLogoClick}>
              <img src={language} alt="Language Logo main" className="languageLogo" height={30} width={30} />
            </IconButton>
            {this.state.languageLogoDrawer && (
                <ClickAwayListener onClickAway={() => this.handleLanguageLogoClick()}>
                <div

                  style={{
                    position: 'absolute',
                    top: '100%',
                    left: '-70%',
                    transform: 'translateX(-50%)',
                    marginTop: '10px',
                    zIndex: 1,
                    padding: '10px',
                    width: '290px',
                    backgroundColor : "#F5F5F5",
                  }}
                >
                  <Box p={2} >
                  <div
                className="languageDropdownTextField" style={{display : "flex",justifyContent : "space-between", alignItems:"center"}}
              ><p id="languageSelected" style={{fontFamily:"Inter"}}>{languages[this.state.selectedLanguage]}</p>
                            <img src={dropdownLogo} alt="Language Logo" className="languageLogo"  />

              </div>

                    <Grid container spacing={1} direction="column" style={{ borderRadius: '17px', backgroundColor:"white", width:"260px", padding:"0.28em 1em", border:"1px solid #e3e3e3", margin:"auto" }}>
                    {Object.keys(languages).map((key:any) => (
                  <Grid item key={key}>
                    <MenuItem
                      className="languageDrawerMenuitem"
                      onClick={() => this.handleLanguageSelect(key)}
                      style={{
                        fontWeight: this.state.selectedLanguage === key ? 600 : 'normal',
                      }}
                    >
                      {languages[key]}
                    </MenuItem>
                  </Grid>
                ))}
                    </Grid>
                  </Box>
                </div>
                </ClickAwayListener>
            )}
          </div>
          
          </LanguageDropdownStyle>
        );
    }

    conditionFunction = () => {
        if (this.state.editProfile) {
            return this.drawerEdit()
        } else if (this.state.contactBarOpen) {
            return this.drawerContact()
        } else if (this.state.techBarOpen) {
            return this.technicalHelp()
        } else {
            return this.drawerMain()
        }

    }
    // Customizable Area End
    render() {
        const BoxView = Box as React.FC<BoxProps>
        const {showStudy} = this.props
        return (
            // Customizable Area Start
            <ModalStyle>
                <BoxView className="main-header-top" >
                    <Grid container justifyContent="space-between" alignItems="center" >
                        <BoxView className="topBox" style={{ display: "flex", alignItems: "center", gap: "20px" }} >
                            <Grid item >
                                <img src={headerLogo} alt="headerLogo" className="main-logo" style={{cursor : "pointer"}}  width={144} onClick={this.navigateToSelectStudy} />
                                <img src={sponcer} id="sponserlOGO" style={{ marginLeft: "8px", cursor : "pointer" }} onClick={this.navigateToSelectStudy} />
                            </Grid>
                           {showStudy && <BoxView style={{ marginTop: "10px" }}>
                                <Typography className="studySelectGrp2"  component={'span'} >{this.state.languageSwitch.Study} : {this.truncateString(this.state.currentStudy,50)}<img className="studySelectGrp" id="buttonStudy" onClick={this.handleStudyDrawerOpen} style={{ verticalAlign: 'middle', marginLeft: '10px' }} src={dropdownLogo} alt="dropdownLogoStudy" /></Typography>
                            </BoxView>}
                        </BoxView>

                        <Grid item style={{display: "flex", alignItems: "center"}} >
                           {
                              showStudy && this.languageDropdown()
                           }
                           
                            <img src={this.state.profilePicture ? this.state.profilePicture : userLogo} alt="headerUser2" className='headerLogo' style={{height:"50px",width:"50px",borderRadius:"50%"}} />
                            <Typography component={"span"} className='userName'>{this.state.userDetails?.full_name}</Typography>
                            <IconButton data-test-id="drawer-btn" onClick={() => this.handleDrawerOpen()}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                            </IconButton>
                            <Hidden mdUp>
                                <IconButton data-test-id="drawer-btnhid" id="handleDraweropen" onClick={() => this.handleDrawerOpen()}>
                                    <MenuIcon />
                                </IconButton>
                            </Hidden>
                        </Grid>
                    </Grid>

                </BoxView>
                {this.drawerStudy()}
                <DrawerStyle
                    anchor="right"
                    open={this.state.open}
                    onClose={this.handleDrawerClose}
                    style={{
                        width: 470
                    }}
                >
                {this.conditionFunction()}
                </DrawerStyle>
                {this.passwordDialog()}
                {this.mailDialog()}
                {this.termsDialog()}
                {this.privacyDialog()}
                {this.logoutDailog()}
                {this.sessionExpiredModal()}
            </ModalStyle>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const StudyModalStyle = styled((Box as React.FC<BoxProps>))({
    "& .topBox": {
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .selectLogo": {
        verticalAlign: 'middle',
        float: 'right',
    },
    "& .MuiOutlinedInput-input": {
        padding: 0,
        height: "38px",
        color: "#D4D3D3",
        fontFamily: "Jost",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        paddingLeft: '16px',
    },
   
    "& .containerStudy": {
        padding: '20px 30px',
        maxWidth: '558px',
        width: '100%',
        borderRadius: "8px",
        background: "#2F2F2F",
        boxShadow: "none",
        position: "absolute",
        top: "10.4%",
        left: "19.4%",
        marginTop:"6.3%",
        "@media (max-width: 1366px)": {
            maxWidth: '500px',
            left: '25%',
        },
        "@media (max-width: 1199px)": {
            maxWidth: '400px',
            left: '28%',
        },
        "@media (max-width: 991px)": {
            maxWidth: '300px',
            left: '34%',
        },
        "@media (max-width: 691px)": {
            maxWidth: '200px',
        },
        "@media (max-height: 1366px)": {
            top: "auto",
        },
        "@media (max-height: 1199px)": {
            top: "auto",
        },
        "@media (max-height: 991px)": {
            top: "auto",
        },
        "@media (max-height: 600px)": {
            top: "auto",
        },
        "@media (max-height: 400px)": {
            top: "auto",
        },
        "@media (min-width: 1600px)": {
            // left: "16.8%",
        },
        "@media (min-width: 1920px)": {
            top: "auto",
        }
    },

    "& .searchStudy": {
        borderRadius: "4px",
        border: "1px solid #474747",
        height: "38px",
        cursor: 'pointer',
        color: "#D4D3D3",
        fontFamily: "Jost",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px"

    },
    "& .studyName.selected": {
        color: "#FFF",
        fontFamily: "Jost",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .studyName": {
        paddingTop: '16px',
        color: "#D4D3D3",
        fontFamily: "Jost",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        cursor: 'pointer',
    }

})
const DrawerStyle = styled(Drawer)<DrawerProps>({
    top: '89px !important',
    height: "calc(100vh - 90px)",
    "& .MuiDrawer-paper": {
        borderRadius: '10px 0 0 10px',
    },
    "& .MuiDrawer-paper, .MuiBackdrop-root": {
        top: '89px !important',
        height: "calc(100vh - 90px)",
    }
})
const ModalStyle = styled((Box as React.FC<BoxProps>))({
    "& .studySelectGrp": {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        cursor: 'pointer',
        padding : "12px",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",         
            borderRadius: '50%',
          },
    },
    "& .studySelectGrp2": {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
    },
    "& .main-header-top": {
        padding: 20,
        position: 'sticky', top: 0, left: 0, right: 0, background: "#FFF",
        borderBottom: "1px solid rgba(161, 161, 161, 0.50)"
    },
    "& .main-logo": {
        width: 145
    },
    "& .headerLogo": {
        verticalAlign: 'middle',
        marginRight: '12px'
    },
    "& .dropdownLogo": {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    "& .userName": {
        color: "#000",
        fontFamily: "Jost",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500
    }
})

const PasswordDialogStyle = styled(Box)({
    "& .passwordError":{
        maxWidth:"300px",
        fontSize:"12px",
        color:"#F44336",
        fontFamily:"Inter",
        marginTop:"5px",
        marginLeft:"14px"
    },
    "& .dialog-title": {
        color: "#000",
        fontSize: "28px",
        fontFamily: "Jost",
        fontWeight: "bolder",
        
    },

    "& .dialog-para": {
        fontWeight: 400,
        fontStyle: "normal",
        width: '90%',
        fontFamily: "Jost",
        fontSize: "16px",
        color: "#5E5E5E",
        
    },
    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },

   
    "& .change-password-btn": {
        height: '50px',
        color: '#000',
        fontFamily: 'Jost',
        border: "2px solid #A046FB",
        marginBottom: '40px',
        borderRadius: "4px",
        marginTop: '32px',
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        fontSize: '14px',
        fontWeight: 'bolder',
        textTransform: 'capitalize',
       
    },
    "& .oldPsswd": {
        fontFamily: 'Jost',
        height: '49px',
        marginTop: '24px',
    },
    "& .newPsswd": {
        height: '49px',
        marginTop: '20px',
        fontFamily: 'Jost',

    },
    "& .cnewPsswd": {
        marginTop: '20px',
        height: '49px',
        fontFamily: 'Jost',
    },
    
})

const MailDialogStyle = styled((Box as React.FC<BoxProps>))({
    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },
    "& .dialog-title": {
        color: "#000",
        fontWeight: "bolder",
        fontFamily: "Jost",
        fontSize: "28px",
        
    },
    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
    "& .dialog-para": {
        color: "#5E5E5E",
        fontStyle: "normal",
        fontSize: "16px",
        width: '90%',
        fontWeight: 400,
        fontFamily: "Jost",
       
    },
    "& .change-password-btn": {
        height: '50px',
        color: '#000',
        textTransform: 'capitalize',
        fontWeight: 'bolder',
        marginBottom: '40px',
        marginTop: '32px',
        border: "2px solid #A046FB",
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        fontFamily: 'Jost',
        fontSize: '14px',
        borderRadius: "4px",
       
    },

    "& .oldEmail": {
        fontFamily: 'Jost',
        marginTop: '24px',
        height: '49px',
    },
    "& .cNewEmail": {
        marginTop: '20px',
        height: '49px',
        fontFamily: 'Jost',
    },
    "& .newEmail": {
        marginTop: '20px',
        height: '49px',
        fontFamily: 'Jost',
    },
   
})
const drawerWidth = 470;
const MainStyle = styled((Box as React.FC<BoxProps>))({
    "& .mainContainer": {
        width: '470px',
        paddingTop: '40px',
        paddingRight: '50px',
        marginBottom: '100px',
        paddingLeft: '38px',
       
    },


    "& .drawerPaper": {
        width: drawerWidth,
        top: '67px'
    },
    "& .drawerHeader": {
        display: 'flex',
        alignItems: 'center',
        padding: "0 8px",
        justifyContent: 'flex-start',
    },

    "& .box": {
        display: 'flex'
    },
    "& .profile": {
        color: '#292929',
        fontFamily: 'Jost',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
    },
    "& .editLogo": {
        float: 'inline-end',
        cursor: 'pointer',
    },
    "& .userName": {
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .userMail": {
        color: "#343434",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400
    },
    "& .userGroup": {
        marginTop: '30px',
        paddingBottom: '30px'
    },
    "& .nameGrp": {
        marginLeft: '15px',
    },
    "& .newFullNameGroup": {
        paddingTop: '28px'
    },
    "& .humanLogo": {
        paddingRight: '16px'
    },
    "& .labelName": {
        color: "#8E8E8E",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400
    },
    "& .valueName": {
        color: "#494949",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500
    },
    "& .newEmailGroup": {
        paddingTop: '28px'
    },
    "& .emailLogo": {
        paddingRight: '16px'
    },

    "& .newPhoneGroup": {
        paddingTop: '28px'
    },
    "& .newAddressGroup": {
        paddingTop: '28px',
        paddingBottom: '14px'
    },
    "& .locationLogo": {
        paddingRight: '16px'
    },
    "& .callLogo": {
        paddingRight: '16px'
    },
    "& .listName": {
        color: "#343434",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        marginLeft: '14px'
    },
    "& .listGroup": {
        paddingTop: '18px'
    },
    "& .listLogo": {
        verticalAlign: 'bottom',
        height: "24px",
        width: "24px"
    },
    "& .rightLogo": {
        float: 'inline-end',
        cursor: 'pointer'
    },
    "& .divider": {
        marginTop: '18px'
    }
})

const EditModalStyle = styled((Box as React.FC<BoxProps>))({
    "& .mainEditContainer": {
        paddingTop: '40px',
        paddingRight: '50px',
        maxWidth: '470px',
        width: '500px',
        paddingLeft: '38px',
        
    },
    "& .errorText": {
        color: 'red',
        fontSize: '14px',
        fontFamily: 'Jost'
    },
    "& .MuiContainer-root": {
        paddingLeft: "39px",
        paddingRight: "38px",
    },
   

    "& .editDrawer": {
        width: drawerWidth,
        flexShrink: 0,
    },
    "& .drawerPaper": {
        width: drawerWidth,
    },
    "& .box": {
        display: 'flex'
    },
    "& .editProfile": {
        color: "#292929",
        fontSize: "24px",
        fontStyle: "normal",
        fontFamily: "Jost",
       
        fontWeight: 700
    },
    "& .editLogo": {
        float: 'inline-end',
        cursor: 'pointer',
    },
    "& .userEditName": {
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
    },

    "& .changeProBtn": {
        color: "#424141",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        textTransform: 'capitalize',
        borderRadius: "67px",
        border: "1px solid #E3E3E3",
        background: "rgba(255, 255, 255, 0.80)",
        padding: '8px'
    },
    "& .userGroup": {
        marginTop: '30px',
        paddingBottom: '30px'
    },
    "& .nameEditGrp": {
        marginLeft: '15px',
        marginTop: '8px',
        display: "flex",
        aligniItems: "center",
        justifyContent: "center",
        gap: "52px",
    },
    "& .newFullNameGroup": {
        paddingTop: '28px',
    },
    "& .boxStyle": {
        paddingTop: '20px',
    },
    "& .labelName": {
        color: "#8E8E8E",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        paddingBottom: '6px'
    },
    "& .fullNameEdit": {
        color: "#494949",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        borderRadius: "67px",
        border: "1px solid #E3E3E3",
        background: "rgba(255, 255, 255, 0.80)",
        maxWidth: '350px',
        width: '100%',
        "& .MuiInputBase-input": {
            padding: '12px 22px'
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none"
        },
        "& .MuiInput-underline:before": {
            borderBottom: "none"
        }
    },

    '& .request': {
        color: "#A046FB",
        textAlign: "center",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        float: "right",
        paddingRight: "44px",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Jost",
        
        cursor: 'pointer'

    },
    "& .updatebtn": {
        height: '50px',
        color: '#000',
        fontFamily: 'Jost',
        marginBottom: '100px',
        borderRadius: "4px",
        border: "2px solid #A046FB",
        background: "#FFF",
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: 'bolder',
       
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        marginTop: '32px',
    },
})

const HelpModalStyle = styled((Box as React.FC<BoxProps>))({

    "& .helpContainer": {
        paddingTop: '40px',
        maxWidth: '450px',
        width: drawerWidth,
        marginLeft: '16px',
       
    },
    "& .MuiContainer-root": {
        paddingLeft: "20px",
        paddingRight: "38px",
    },

    "& .editDrawer": {
        width: drawerWidth,
        flexShrink: 0,
    },
    "& .drawerPaper": {
        width: drawerWidth,
    },
    "& .box": {
        display: 'flex'
    },
    "& .editProfile": {
        color: "#292929",
        fontSize: "24px",
        fontStyle: "normal",
        fontFamily: "Jost",
       
        fontWeight: 700
    },
    "& .cancelBtn": {
        float: 'right',
        marginRight: "-10px",
        cursor: 'pointer',
        marginTop: '-20px'
    },
    "& .MuiAccordionSummary-root ": {
        // display: "flex",
        paddingRight: '20px',
        padding: '0px',
       
    },
    "& .MuiPaper-elevation1": {
        boxShadow: 'none'
    },
    "& .MuiAccordionDetails-root": {
        padding: '0px'
    },
    "& .header": {
        color: "#484848",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        fontFamily: "Inter",
       
        // padding: '6px 0px'
    },
    "& .para": {
        color: "#616161",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: '24px'
    },
    "& .MuiTypography-body1": {
        letterSpacing: '0'
    },
    "& .MuiAccordion-root:before": {
        width: '94%',
        background: "#EBEBEB",
    }
})

const ContactModalStyle = styled((Box as React.FC<BoxProps>))({
    "& .contactContainer": {
        paddingTop: '40px',
        paddingLeft: '48px',
        marginBottom: '30px',
        maxWidth: '470px',
        width: '100%',
        paddingRight: '50px',
       

    },
    "& .errorText": {
        fontFamily: 'Jost',
        fontSize: '14px',
        color: 'red',
    },
    "& .MuiContainer-root": {
        paddingRight: "38px",
        paddingLeft: "39px",
       
    },

    "& .editDrawer": {
        flexShrink: 0,
        width: drawerWidth,
        
    },
    "& .drawerPaper": {
        width: drawerWidth,
    },
    "& .box": {
        display: 'flex'
    },
    "& .contactUs": {
        color: "#292929",
        fontFamily: "Jost",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .cancelBtn": {
        float: 'right',
        cursor: 'pointer'
    },
    "& .talkText": {
        paddingTop: '10px',
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500
    },
    "& .talkPara": {
        paddingTop: '8px',
        color: "#616161",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        width: '90%'
    },
    "& .cntGrp": {
        marginTop: '10px',

    },
    "& .phneGroup": {
        marginTop: '26px',
        marginLeft: '12px',
    },
    "& .phone": {
        color: "#8E8E8E",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    "& .pNumber": {
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .availTime": {
        color: "#7D7D7D",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    "& .emailGrp": {
        marginTop: '12px',
        marginLeft: '12px',
    },
    "& .emailTxt": {
        color: "#8E8E8E",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    "& .supportEmail": {
        color: "#484848",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .sendText": {
        color: "#4D4D4D",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        paddingTop: '40px'
    },

    "& .cntLabelName": {
        color: "#7D7D7D",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400
    },

    "& .cntFullNameEdit": {
        marginTop: '4px',
        color: "#424141",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        borderRadius: "67px",
        border: "1px solid #E3E3E3",
        background: "rgba(255, 255, 255, 0.80)",
        maxWidth: '350px',
        width: '100%',
        "& .MuiInputBase-input": {
            padding: '12px 22px'
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none"
        },
        "& .MuiInput-underline:before": {
            borderBottom: "none"
        },

    },

    "& .cntFullNameEditSelect": {
        marginTop: '0px',
        color: "#424141",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        borderRadius: "67px",
        border: "1px solid #E3E3E3",
        background: "rgba(255, 255, 255, 0.80)",
        maxWidth: '350px',
        width: '100%',
        "& .MuiInputBase-input": {
            padding: '12px 22px'
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none"
        },
        "& .MuiInput-underline:before": {
            borderBottom: "none"
        },
    },

    "& .boxStyle": {
        paddingTop: '20px',
    },

    "& .MuiNativeSelect-select:focus": {
        marginTop: '0px',
        color: "#424141",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        borderRadius: "67px",
        maxWidth: '350px',
        width: '100%',
        background: "none",
    },

    "& .submitCntBtn": {
        borderRadius: "53px",
        background: "#A046FB",
        color: "#FFF",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: 'capitalize',
        marginTop: '30px',
        height: '48px',
        maxWidth: '356px',
        width: '100%'
    }


})

const TermsDialogStyle = styled((Box as React.FC<BoxProps>))({

    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },
    "& .termContainer": {
        width: '100%',
        maxWidth: '1100px',
    },
    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
   
    " & .termsText": {
        color: "#000",
        fontSize: "28px",
        fontStyle: "normal",
        fontFeatureSettings: "'clig' off, 'liga' off",
        lineHeight: "38px",
        fontFamily: "Jost",
       
        fontWeight: 700,
    },
    "& .dialogContent": {
        fontSize: "14px",
        color: "#3D3C3C",
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "26px",
        fontFamily: "Inter",
        
    },
    "& .box": {
        // paddingTop: '60px',
    },
    "& .dialogContentPara": {
        fontFamily: "Inter",
        paddingTop: '20px',
        color: "#3D3C3C",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "26px",
        fontStyle: "normal",
        marginBottom: '10px'
    },
})

const PrivacyDialogStyle = styled((Box as React.FC<BoxProps>))({
    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },
    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
    " & .privacyText": {
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Jost",
        color: "#000",
        fontSize: "28px",
        lineHeight: "38px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .privacyContainer": {
        width: '100%',
        maxWidth: '1100px',
    },
   
    "& .dialogContent": {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        color: "#3D3C3C",
        fontStyle: "normal",
        lineHeight: "26px"
    },
    "& .box": {
        // paddingTop: '60px',
    },

    "& .dialogContentPara": {
        color: "#3D3C3C",
        fontFamily: "Inter",
        paddingTop: '20px',
        fontSize: "14px",
        lineHeight: "26px",
        marginBottom: '10px',
        fontStyle: "normal",
        fontWeight: 400,

    },



})

const LanguageDropdownStyle = styled((Box as React.FC<BoxProps>))({
   
    "& .languageDrawerMenuitem": {
        padding: 0,
        height: "38px",
        color: "#5A5A5A",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        paddingLeft: '16px',
    },
    "& .languageDropdownTextField": {
        fontfamily: "Inter",
        fontSize : "14px",
        fontWeigth: 600,
        lineHeight : "26px",
        textAlign : "left",
        color : "#5A5A5A",
        borderRadius : "67px !important",
        backgroundColor : "white",
        padding : "0.28em 2em",
        fontWeight : "bold",
        border:"1px solid #e3e3e3"

    },
    "&. languageSwitchButton" : {
        display : "flex",
        justifyContent : "space-between"
    }

})


export default SideNavbar;