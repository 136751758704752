import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData,setStorageData } from "framework/src/Utilities";
export interface Milestone {
  id: string;
  type: string;
  attributes: {
    id: number;
    start_date: string;
    end_date: string;
    milestone_description: string;
    name: string;
  };
}
export interface Site {
  id: string;
  type: string;
  attributes: {
    id: number;
    site_name: string;
    site_country: string;
    site_address: string;
    account_id: number;
    site_number: string;
    archived: boolean;
    site_coordinator_name : string;
  };
}

export interface MilestoneProgress {
  milestones: {
    total_milestone: number,
    current_milestone: number,
    current_milestone_details: string,
    progress: number
},
  total_earned_points: number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  selectedStudy: string | null;
  total_Milestones: number;
  current_Milestones: number;
  milestones: Milestone[];
  sites: Site[];
  studyDescription: string;
  studyName: string;
  studyNumber: string;
  circularPerDash : number,
  translations : {
    Dashboard: string;
    Total: string;
    Milestone: string;
    Milestone_Details: string;
    Points: string;
    Duration: string;
    Start_Date: string;
    End_Date: string;
    Study_Number: string;
    Site_Details: string;
    Site_ID: string;
    Site_Coordinator: string;
    Site_Country: string;
    Site_Address: string;
    Current_Milestone : string,
    DashboardDescription : string
    Current: string,
    DetailOf: string,
    Site: string,
    month: Array<string>
  },
  selectedLanguage : string,
  milestoneData: MilestoneProgress;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStudyApiCallId: string = "";
  getAllStudyApiCallId: string = "";
  getAllPatientsDataApiCallId : string ="";
  getMilestoneID: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      selectedStudy: "",
      total_Milestones: 0,
      current_Milestones: 0,
      milestones: [],
      sites: [],
      studyDescription: "",
      studyName: "",
      studyNumber: "",
      circularPerDash : 0,
      translations : {
        Dashboard: "",
        Total: "",
        Milestone: "",
        Milestone_Details: "",
        Points: "",
        Duration: "",
        Start_Date: "",
        End_Date: "",
        Study_Number: "",
        Site_Details: "",
        Site_ID: "",
        Site_Coordinator: "",
        Site_Country: "",
        Site_Address: "",
        Current_Milestone : "",
        DashboardDescription : "",
        Current: "",
        DetailOf: "",
        Site: "",
        month: [],

      },
      selectedLanguage : "",
      milestoneData: {
        "milestones": {
            "total_milestone": 1,
            "current_milestone": 0,
            "current_milestone_details": "",
            "progress": 0
        },
        "total_earned_points": 0
      },
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const handleRestApiResponse = (message: Message) => {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if(apiRequestCallId === this.getStudyApiCallId){
          if(responseJson.errors){
            this.navigateToLogin()
          }
          this.setState({
            milestones : responseJson.data.attributes.milestone_managements.data,
            sites : responseJson.data.attributes.site_ids.data,
            studyDescription : responseJson.data.attributes.study_description,
            studyName : responseJson.data.attributes.study_name,
            studyNumber : responseJson.data.attributes.study_number,
          },()=>{
              this.calculateCurrentMilestone(this.state.milestones);
          })
          this.getAllPatientsData();
        }
        if(apiRequestCallId === this.getAllPatientsDataApiCallId){
          const completed = responseJson.completed_milestones;
          const total = responseJson.total_milestone_count;
          if(total > 0){
            if(completed >0){
              this.setState({
                circularPerDash : (completed/total)*100
              })
            }
            return 0;
          }
          return 0;
        }
        this.mileStoneApiCallHandle(apiRequestCallId, responseJson);
      }
    };

    handleRestApiResponse(message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.scrollToTop();
    this.getSelectedLanguage();
    this.setState(
      {
        selectedStudy: localStorage.getItem("studyNumber"),
      },
      () => {
        this.getMilestoneData();
        this.getStudyDetails();
      }
    );
  }

  mileStoneApiCallHandle = (
    apiRequestCallId: string,
    responseJson: MilestoneProgress
  ) => {
    if (apiRequestCallId == this.getMilestoneID) {
      if(responseJson?.milestones){
      this.setState({ milestoneData: responseJson });
      }
    }
  };

  getStudyDetails = () => {
    let token =
      typeof window !== "undefined" ? localStorage.getItem("token") : null;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStudyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudyApiCallIdEndPoint}/${this.state.selectedStudy}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    window.scroll(0, 0);
  };
  calculateDuration = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const ends = new Date(endDate);
    const diffTime = Math.abs(ends.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = Math.floor(diffDays / 30);
    const remainingDays = diffDays % 30;

    switch (true) {
      case diffMonths > 0 && remainingDays === 0:
        return `${diffMonths} month${diffMonths > 1 ? "s" : ""}`;
      case diffMonths > 0 && remainingDays > 0:
        return `${diffMonths} month${
          diffMonths > 1 ? "s" : ""
        }, ${remainingDays} day${remainingDays > 1 ? "s" : ""}`;
      default:
        return `${diffDays} day${diffDays > 1 ? "s" : ""}`;
    }
  };

  calculateCurrentMilestone = (milestones: Milestone[]) => {
    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth() + 1;
    const todayDay = today.getDate();

    let currentMilestoneIndex: number = 0;

    for (let iter = 0; iter < milestones.length; iter++) {
      const milestone = milestones[iter];
      const startDate = new Date(milestone.attributes.start_date);
      const endDate = new Date(milestone.attributes.end_date);
      const startYear = startDate.getFullYear();
      const startMonth = startDate.getMonth() + 1;
      const startDay = startDate.getDate();
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth() + 1;
      const endDay = endDate.getDate();
      if (
        todayYear === startYear &&
        todayMonth === startMonth &&
        todayDay >= startDay &&
        todayYear === endYear &&
        todayMonth === endMonth &&
        todayDay <= endDay
      ) {
        currentMilestoneIndex = iter + 1;
        break;
      }
    }
    this.setState({
      current_Milestones: currentMilestoneIndex,
    });

    return currentMilestoneIndex;
  };
  formatDate(dateString: string): string {
    const dateParts: string[] | undefined = dateString?.split("-");
    const days: number = parseInt(dateParts?.[2] || "0", 10);
    const month: string = this.getMonthName(
      parseInt(dateParts?.[1] || "0", 10)
    );
    const year: number = parseInt(dateParts?.[0] || "0", 10);

    const formattedDay: string = this.getFormattedDay(days);

    return `${formattedDay} ${month} ${year}`;
  }

  getMonthName(monthNumber: number): string {
    const months: string[] = this.state.translations.month;
    return months[monthNumber - 1] || "";
  }

  getFormattedDay(days: number): string {
    if (days >= 11 && days <= 13) {
      return days + "th";
    }
    switch (days % 10) {
      case 1:
        return days + "st";
      case 2:
        return days + "nd";
      case 3:
        return days + "rd";
      default:
        return days + "th";
    }
  }

  formatNumber = (nums: number) => {
    if (nums < 10) {
      return "0" + nums;
    } else {
      return nums;
    }
  };

  calculateTotalDuration = (milestones: Milestone[]) => {
    let totalDays = 0;

    milestones.forEach((milestone: Milestone) => {
      const { start_date, end_date } = milestone.attributes || {};
      if (!start_date || !end_date) {
        return;
      }
      const [startYear, startMonth, startDay] = start_date
        .split("-")
        .map(Number);
      const [endYear, endMonth, endDay] = end_date.split("-").map(Number);
      const startDate = new Date(startYear, startMonth - 1, startDay);
      const endDate = new Date(endYear, endMonth - 1, endDay);
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      totalDays += diffDays;
    });

    const totalMonths = Math.floor(totalDays / 30);
    const remainingDays = totalDays % 30;

    switch (true) {
      case totalMonths > 0 && remainingDays === 0:
        return `${totalMonths} month${totalMonths > 1 ? "s" : ""}`;
      case totalMonths > 0 && remainingDays > 0:
        return `${totalMonths} month${
          totalMonths > 1 ? "s" : ""
        }, ${remainingDays} day${remainingDays > 1 ? "s" : ""}`;
      default:
        return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
    }
  };
  navigateToLogin() {
    const navigateToLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    navigateToLoginMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateToLoginMessage);
  }
  getAllPatientsData=async()=>{
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPatientsDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllPatientsDataApi}?study_id=${this.state.selectedStudy}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newLanguage = event.target.value as string;
    const translations = configJSON.translations[newLanguage];

    this.setState({
      selectedLanguage: newLanguage,
      translations: {
        Dashboard: translations.Dashboard,
        Total: translations.Total,
        Milestone: translations.Milestone,
        Milestone_Details: translations.Milestone_Details,
        Points: translations.Points,
        Duration: translations.Duration,
        Start_Date: translations.Start_Date,
        End_Date: translations.End_Date,
        Study_Number: translations.Study_Number,
        Site_Details: translations.Site_Details,
        Site_ID: translations.Site_ID,
        Site_Coordinator: translations.Site_Coordinator,
        Site_Country: translations.Site_Country,
        Site_Address: translations.Site_Address,
        Current_Milestone : translations.Current_Milestone,
        DashboardDescription : translations.DashboardDescription,
        Current: translations.Current,
        DetailOf: translations.DetailOf,
        Site: translations.Site,
        month : translations.month,

      }
    });

    setStorageData('lang', newLanguage);
  };

  getSelectedLanguage = async () => {
    let selLanguage = await getStorageData('lang');
    this.setState({ selectedLanguage: selLanguage }, () => {
      this.getInitialValues();
    });
  };
  getInitialValues = ()=>{
    window.scroll(0,0);
    const translations = configJSON.translations[this.state.selectedLanguage];

    this.setState({
      translations: {
        End_Date: translations.End_Date,
        Study_Number: translations.Study_Number,
        Site_Details: translations.Site_Details,
        Site_ID: translations.Site_ID,
        Site_Coordinator: translations.Site_Coordinator,
        Site_Country: translations.Site_Country,
        Site_Address: translations.Site_Address,
        Dashboard: translations.Dashboard,
        Total: translations.Total,
        Milestone: translations.Milestone,
        Milestone_Details: translations.Milestone_Details,
        Points: translations.Points,
        Duration: translations.Duration,
        Start_Date: translations.Start_Date,
        Current_Milestone : translations.Current_Milestone,
        DashboardDescription : translations.DashboardDescription,
        Current: translations.Current,
        DetailOf: translations.DetailOf,
        Site: translations.Site,
        month : translations.month,
        
      }
    });
  
  }
  scrollToTop = () => {
    setTimeout(() => {
      if(typeof document !== "undefined"){
        const contactUsHeading : any = document.getElementById('topScroll');
        contactUsHeading.scrollIntoView();
      }
    }, 0);
  };

  getMilestoneData = async () => {
   
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getMilestoneID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllMilestoneShowApiEndPoint}${this.state.selectedStudy}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}