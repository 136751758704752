import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    Hidden,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    DialogContent,
    styled,
    CircularProgress,
    IconButton,
    Snackbar,
    BoxProps,
    Select,
    MenuItem,
  } from "@material-ui/core";
  import {
    backDrop,
    boxheaderLogo,
    buttonCancel,
    emailLogo,
    headerLogo,
    languageLogo,
    dropdownLogo,
    lockLogo,
    rightLogo,
    sideLogo,
    sponcer,
  } from "./assets";
  import { Visibility, VisibilityOff } from "@material-ui/icons";
// Customizable Area End

import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";
export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    logo = () => {
        return (
          <Grid item xs={12}>
            <Box className="form-logo">
              <img src={boxheaderLogo} alt="boxheader-logo" />
            </Box>
          </Grid>
        );
      };
    
      dialog = () => {
        const { password, confirmPassword, confirmPasswordError, passwordError } =
          this.state;
        return (
          <Dialog
            data-test-id="closeIcon"
            open={this.state.open}
            onClose={() => this.handleClose()}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogStyle>
              <DialogActions>
                <img
                  src={buttonCancel}
                  alt="button-cancel-logo"
                  onClick={() => this.handleClose()}
                  style={{ cursor: "pointer" }}
                />
              </DialogActions>
              <DialogTitle>
                <Typography className="dialog-title">{this.state.languageSwitch.setPass}</Typography>
              </DialogTitle>
              <DialogContent>
                <Typography className="dialog-para" style={{fontFamily:"Jost"}}>
                  {this.state.languageSwitch.setPassMsg}
                </Typography>
                <Grid item xs={12}>
                  <TextField
                    data-test-id="passwordClick"
                    type={this.state.showPassword ? "text" : "password"}
                    fullWidth
                    id="password"
                    variant="outlined"
                    placeholder={this.state.languageSwitch.EnterNewPassPh}
                    value={password}
                    onChange={(e) => {
                      this.handlePasswordChange(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={lockLogo} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" data-test-id="icon-test">
                          {password.length > 0 ? (
                            <IconButton
                              id="password-Visible"
                              aria-label="toggle password visibility"
                              onClick={() => this.handleClickShowPassword()}
                            >
                              {this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ) : null}
                        </InputAdornment>
                      ),
                      className: "newPswd",
                    }}
                    error={passwordError !== ""}
                  />
                  <Typography className="passwordError">
                    {this.state.passwordError}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    fullWidth
                    variant="outlined"
                    placeholder={this.state.languageSwitch.confirmNewPassPh}
                    id="cPassword"
                    value={confirmPassword}
                    onChange={(e) =>
                      this.handleConfirmPasswordChange(e.target.value)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={lockLogo} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {confirmPassword.length > 0 ? (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => this.handleClickShowConfirmPassword()}
                            >
                              {this.state.showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ) : null}
                        </InputAdornment>
                      ),
                      className: "ConfirmNewPswd",
                    }}
                    error={confirmPasswordError !== ""}
                    helperText={confirmPasswordError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    className="change-password-btn"
                    disabled={this.state.isLoading}
                    fullWidth
                    onClick={() => this.submitPassWord()}
                  >
                    {this.state.isLoading ? (
                      <CircularProgress size={30} style={{ color: "#A046FB" }} />
                    ) : (
                      `${this.state.languageSwitch.createPasswordBtn}`
                    )}
                  </Button>
                </Grid>
              </DialogContent>
            </DialogStyle>
          </Dialog>
        );
      };

    Terms = () => {
  
      return (
          <Grid item xs={12}>
              <Box className="form-tnc">
                  <Typography className="form-termsgroup">
                      {this.state.languageSwitch.tnc}
                      <Typography className="form-terms" ><Typography className="form-terms" style={{cursor:"pointer"}} component={'span'} data-test-id="handleTermsBtn" onClick={()=>this.handleTermsClick()} >{this.state.languageSwitch.terms}</Typography> 
                          <Typography className="form-termsgroup" component={"span"}> {this.state.languageSwitch.and} </Typography>
                          <Typography className="form-terms" component={'span'} id="handlePrivacyBtn" style={{cursor:"pointer"}}  onClick={()=>this.handlePrivacyClick()}>{this.state.languageSwitch.prp}</Typography></Typography>
                          {this.state.languageSwitch.last}
                  </Typography>
                 
              </Box>
          </Grid>
      )
  }
  termsDialog = () => {
    const { termsAndConditions } = this.state;

    const BoxView = Box as React.FC<BoxProps>
    return (
        <Dialog
            scroll="body"
            className="termContainer"
            fullWidth
            open={this.state.showTerms}
            maxWidth={'lg'}
            aria-labelledby="responsive-dialog-title" >
            <TermsDialogStyle>
                <DialogActions>
                    <IconButton id="cancel-terms" onClick={() => this.handleCloseTerms()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                    </IconButton>
                </DialogActions>
                <DialogTitle >
                    <Typography className='termsText'>{this.state.languageSwitch.terms}</Typography>
                </DialogTitle>
                <DialogContent >
                    <BoxView className="box">
                        <div className="dialogContent" dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
                    </BoxView>
                </DialogContent>
            </TermsDialogStyle>
        </Dialog>
    )
}
privacyDialog = () => {
  const { privacyPolicy } = this.state
  const BoxView = Box as React.FC<BoxProps>
  return (
      <Dialog
          scroll="body"
          maxWidth={'lg'}
          className="privacyContainer"
          fullWidth
          open={this.state.showPrivacy}
          aria-labelledby="responsive-dialog-title" >
          <PrivacyDialogStyle>
              <DialogActions>
                  <IconButton id="cancel-privacy" onClick={() => this.handleClosePrivacy()}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                  </IconButton>
              </DialogActions>
              <DialogTitle >
                  <Typography className='privacyText'>{this.state.languageSwitch.prp}</Typography>
              </DialogTitle>
              <DialogContent >
                  <BoxView className="box">
                      <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} className="dialogContent"  />
                  </BoxView>
              </DialogContent>
          </PrivacyDialogStyle>
      </Dialog>
  )
}
language = () => {
  return (

      <Grid>
          <Box style={{ textAlign: 'center', marginTop: '33px' }}>
            <div style={{display:"flex",alignContent:"center",justifyContent:"center"}}>
              <img src={languageLogo} width={20} height={20} alt="Language-Logo" style={{ verticalAlign: 'top' }} />
              <Typography component={"span"} className="languageTxt">{this.state.languageSwitch.language}
                  <Select
            value={this.state.selectedLanguage}
            onChange={this.handleLanguageChange}
            onClose={this.handleToggleLanguageMode}
            open={this.state.languageMode}
            className="englishtxt"
            displayEmpty
            style={{
              marginLeft: '10px',
              verticalAlign: 'middle',
              borderBottom: 'none',
            }}
            IconComponent={() => null}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="de">German</MenuItem>
            <MenuItem value="fr">French</MenuItem>
            <MenuItem value="es">Spanish</MenuItem>
          </Select>
                      <img src={dropdownLogo} onClick={this.handleToggleLanguageMode} id="dropdownLogo" alt="dropdown-logo" style={{ verticalAlign: 'middle', marginLeft: '4px', cursor: 'pointer' }} />
              </Typography>
              </div>
          </Box>
      </Grid>
  )
}
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            emailScreen,
            otpScreen,
            passwordSuccess,
            emailError,
            email,
            otp,
            timerActive,
          } = this.state;
        return (
            <>
              <ModalStyle>
          <Paper elevation={0} className="paper-container">
            <Box style={{ position: "sticky", top: 0, left: 0, right: 0 }}>
              <header className="header">
                <Container maxWidth="lg">
                  <Grid item className="registrationLogo">
                    <img id="headerLogo" src={headerLogo} alt="headerLogo" width={144} onClick={this.navigateToSignUp} />
                    <img src={sponcer} style={{ marginLeft: "8px" }} onClick={this.navigateToSignUp} />
                  </Grid>
                </Container>
              </header>
            </Box>
            <Container maxWidth="lg">
              <Grid container className="main" alignItems="center">
                <Hidden smDown>
                  <Grid item xs={12} md={6}>
                    <Typography className="textPara">
                      {this.state.languageSwitch.pageHeader}
                    </Typography>
                    <img src={sideLogo} alt="side-logo" />
                  </Grid>
                </Hidden>

                {emailScreen && (
                  <Grid container item xs={12} md={6} justifyContent="center">
                    <Grid item xs={10}>
                      <Box className="box">
                        <Grid container spacing={3}>
                          {this.logo()}
                          <Grid item xs={12}>
                            <Box className="form-logo">
                              <Typography style={{fontFamily:"Inter"}}>
                               {this.state.languageSwitch.emailBoxHeader}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              onChange={(e) =>
                                this.handleEmailChange(e.target.value)
                              }
                              variant="outlined"
                              placeholder={this.state.languageSwitch.emailInputPlaceholder}
                              id="email"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={emailLogo} />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={
                                        emailError === false ? rightLogo : ""
                                      }
                                      className="right-logo"
                                    />
                                  </InputAdornment>
                                ),
                                style: { height: 49 },
                                className:"loginEmail"
                              }}
                              className="emailPlaceholder"
                              value={email}
                              error={emailError}
                              helperText={
                                !emailError ? "" : this.state.emailErrorText
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              disabled={this.state.isLoading}
                              className="continueBtn"
                              fullWidth
                              onClick={() => {
                                this.handleEmailSubmit();
                              }}
                            >
                              {this.state.isLoading ? (
                                <CircularProgress
                                  size={30}
                                  style={{ color: "#A046FB" }}
                                />
                              ) : (
                                `${this.state.languageSwitch.continueBtnText}`
                              )}
                            </Button>
                          </Grid>
                          {this.Terms()}
                        </Grid>
                      </Box>
                      {this.language()}
                    </Grid>
                  </Grid>
                )}



                {otpScreen && (
                  <Grid container item xs={12} md={6} justifyContent="center">
                    <Grid item xs={10}>
                      <Box className="box">
                        <Grid container spacing={3} className="form-content">
                          {this.logo()}
                          <Grid item xs={12}>
                            <Box className="form-logo">
                              <Typography className="enter-otp">
                               {this.state.languageSwitch.sixDigitOtp}{" "}
                                <Typography
                                  component={"span"}
                                  className="edit-email"
                                  onClick={this.handleEditEmailAddress}
                                >
                                  {this.state.languageSwitch.editEmailMsg}
                                </Typography>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder="Email Address"
                              value={email}
                              disabled
                              className="disabledemail"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={emailLogo} />
                                  </InputAdornment>
                                ),
                                style: { height: 49, backgroundColor:"#f4f4f4", border:"1px solid #e6e6e6" },
                                className : "disabledemail"
                              }}
                             
                            />
                            <Box className="timer"> {this.displayTime()}</Box>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="otpInput"
                              placeholder="000000"
                              InputProps={{
                                className: "otp",
                              }}
                              onKeyDown={(e) => this.handleOtpChange(e)}
                              onChange={(e) => this.handleOtpChange(e)}
                              value={otp}
                              error={!this.state.validOtp || !!this.state.otpError} 
  helperText={
    !this.state.validOtp
      ? this.state.languageSwitch.wrongOtpEr
      : this.state.otpError
  }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Box className="form-otp">
                              <Typography className="didnot-got">
                                {this.state.languageSwitch.didNotGetOtp}
                                <Typography
                                  component={"span"}
                                  data-test-id="resend"
                                  className={`resend-otp ${
                                    timerActive ? "active" : "inactive"
                                  }`}
                                  onClick={this.handleResendOtp}
                                >
                                 {this.state.languageSwitch.resendOtp}
                                </Typography>
                                <Snackbar
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  open={this.state.openSnackbar}
                                  autoHideDuration={3000}
                                  onClose={this.handleSnackbarClose}
                                  message="OTP Resent!"
                                  action={
                                    <Button
                                      color="secondary"
                                      size="small"
                                      onClick={this.handleSnackbarClose}
                                    >
                                      <img src={buttonCancel} alt="cancelBtn" />
                                    </Button>
                                  }
                                />
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              className="submitBtn"
                              fullWidth
                              disabled={this.state.isLoading}
                              onClick={() => this.handleClickOpen()}
                            >
                              {this.state.isLoading ? (
                                <CircularProgress
                                  size={30}
                                  style={{ color: "#A046FB" }}
                                />
                              ) : (
                               ` ${this.state.languageSwitch.submitBtn}`
                              )}
                            </Button>
                            {this.dialog()}
                            
                          </Grid>
                          {this.Terms()}
                        </Grid>
                      </Box>
                      {this.language()}

                    </Grid>
                  </Grid>
                )}

                {passwordSuccess && (
                  <Grid container item xs={12} md={6} justifyContent="center">
                    <Grid item xs={10}>
                      <Box className="box">
                        <Grid container spacing={3}>
                          {this.logo()}
                          <Grid item xs={12}>
                            <Box className="form-logo">
                              <Typography className="form-welcome">
                                {this.state.languageSwitch.welcomeMsg}
                              </Typography>
                              <Typography className="form-created">
                                {this.state.languageSwitch.passwordCreatedMsg}{" "}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              className="loginPswrdBtn"
                              id="btn-navigate"
                              onClick={() => this.loginNavigationButton()}
                              fullWidth
                            >
                            {this.state.languageSwitch.loginWithPass}
                            </Button>
                          </Grid>
                          {this.Terms()}
                        </Grid>
                      </Box>
                      {this.language()}

                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Container>
          </Paper>
          {this.termsDialog()}
          {this.privacyDialog()}
        </ModalStyle>
            
            </>
        );
        // Customizable Area End

    }
}
// Customizable Area Start
const ModalStyle = styled(Box)({
  "& .header": {
      backgroundColor: "#fff",
      padding: '30px 0',
  },
  "& .paper-container": {
      backgroundImage: `url(${backDrop})`,
      overflowX: 'hidden',
      height: "100vh",
      overflow : "hidden"
  },
  "& .pleaseText": {
      fontWeight: 400,
      textAlign: "center",
      fontFamily: "Inter",
      color: "#767E85",
      fontSize: "14px",
      fontStyle: "normal",
      fontFeatureSettings: "'clig' off, 'liga' off",
  },
  "& .main": {
      height: "calc(100vh - 200px)",
},
  "& .paper": {
      padding: 2,
      textAlign: 'center',
      
  },
  "& .box": {
      padding: 30,
      boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
      borderRadius: "10px",
      border: "2px solid #A046FB",
      alignItems: 'center',
      background: "#FFF",
      
  },
  "& .textPara": {
      fontStyle: "normal",
      marginBottom: '100px',
      fontFamily: "Jost",
      marginLeft: '20px',
      textTransform: "capitalize",
      fontSize: "30px",
      fontWeight: 700,
      color: "#0F2220",
  },
  "& .emailPlaceholder": {
    marginTop: "56px",
    fontFamily: "Jost",
    color: "#0F2220",
    fontSize: "30px",
},
  "& .form-logo": {
      textAlign: "center",
      color: "#767E85",
      "@media (max-width: 600px)": { 
        fontSize: "8px",
      }
     
  },
  "& .form-tnc": {
      textAlign: 'center',
  },

  "& .form-terms": {
      fontWeight: 400,
      fontFamily: 'Jost',
      fontSize: '12px',
      color: "#A046FB",
  },
  "& .form-termsgroup": {
      fontFamily: 'Jost',
      fontWeight: 400,
      fontSize: '12px',
      color: "#2B2B2B",
  },
  "& .englishTxt": {
      fontStyle: "normal",
      fontSize: "14px",
      color: "#A046FB",
      marginLeft: '4px',
      fontFamily: "Inter",
      fontWeight: "bold",
     
  },
  "& .continueBtn": {
    height: 50,
    marginTop: 97,
    fontFamily: "Jost",
    fontWeight: 'bolder',
    fontSize: "16px",
    color: "#0F2220",
    boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
    borderRadius: "4px",
    textTransform: 'capitalize',
    background: "#FFF",
    border: "2px solid #A046FB",
},
  "& .languageTxt": {
      marginLeft: '4px',
      fontFamily: "Inter",
      color: "#000",
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "14px",
     
  },
  "& .enter-otp": {
      textAlign: "center",
      fontSize: "14px",
      color: "#767E85",
      fontStyle: "normal",
      
      fontWeight: 400,
      fontFamily: "Inter",
      "@media (max-width: 600px)": { 
        fontSize: "14px",
      }
  },
  "& .form-content": {
      textAlign: 'center'
  },
  "& .edit-email": {
      fontWeight: 700,
      color: "#A046FB",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "14px",
      cursor:"pointer"
  },
  "& .otp": {
      marginTop:'-20px',
      height: "49px",
      "@media (max-width: 600px)": { 
        fontSize: "15px",
      }
  },
  "& .disabledemail": {
      fontFamily: "Inter",
      fontSize: "17px",
      color: "#939393",
      "@media (max-width: 600px)": { 
        fontSize: "15px",
      }
  },
  "& .form-otp": {
      textAlign: 'end',
      marginTop:'-20px',
      
  },
  "& .resend-otp.active": {
      color: "#767E85 !important",
      fontFamily: "Inter",
      marginLeft: '6px',
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      cursor: 'pointer',
      "@media (max-width: 600px)": { 
        fontSize: "12px",
      }
  },
  "& .resend-otp.inactive": {
      fontWeight: 600,
      marginLeft: '6px',
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "14px",
      color: "#A046FB !important",
      "@media (max-width: 600px)": { 
        fontSize: "12px",
      }
  },
  "& .resend-otp": {
      fontWeight: 600,
      marginLeft: '6px',
      fontFamily: "Inter",
      fontSize: "14px",
      color: "#767E85 !important",
      fontStyle: "normal",
      cursor: 'pointer',
      "@media (max-width: 600px)": { 
        fontSize: "12px",
      }
  },
  "& .didnot-got": {
      fontFamily: "Inter",
      color: "#767E85",
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "normal",
      "@media (max-width: 600px)": { 
        fontSize: "12px",
      }
  },
  "& .submitBtn": {
      borderRadius: "4px",
      height: 50,
      textTransform: 'capitalize',
      background: "#FFF",
      fontWeight: 'bolder',
      fontFamily: "Jost",
      fontSize: "16px",
      boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
      color: "#0F2220",
      border: "2px solid #A046FB",
  },
  "& .timer": {
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: 400,
      color: "#7D7D7D",
      marginTop:'10px',
      textAlign: 'end',
      fontStyle: "normal",
     
  },
  "& .form-created": {
      marginTop: '16px',
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "normal",
      color: "#767E85",
  },
  "& .form-welcome": {
      fontSize: "20px",
      fontWeight: "bolder",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Inter",
      color: "#535655",
      fontStyle: "normal",
  },
"& .newPswd": {
    marginTop: '24px',
    height: '49px',
    fontFamily: 'Jost',
},
  "& .loginPswrdBtn": {
      boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
      borderRadius: "2px",
      fontFamily: 'Jost',
      background: "#A046FB",
      marginTop: '140px',
      fontWeight: 'bold',
      border: "2px solid #A046FB",
      color: '#fff',
      textTransform: 'capitalize',
      height: '48px',
  },
  "& .loginEmail": {
      fontWeight: 400,
      marginTop: '20px',
      fontSize: "16px",
      fontStyle: "normal",
      color: "var(--grey-base, #939393)",
      fontFamily: "Inter",
      "@media (max-width: 600px)": { 
        fontSize: "15px",
      }
  },
  "& .MuiInputBase-input": {
   height : "2px !important" 
},
  "& .right-logo": {
    "@media (max-width: 600px)": { 
     marginRight:"-14px"
    }
},
  "& .dialog-title": {
    fontSize: "28px",
    color: "#000",
    fontWeight: "bolder",
    fontFamily: "Jost",
},
"& .dialog-para": {
    fontStyle: "normal",
    fontWeight: 400,
    width: '90%',
    color: "#5E5E5E",
    fontFamily: "Jost",
    fontSize: "16px",
},
  "& .welcome": {
      fontStyle: "normal",
      fontWeight: 600,
      fontFamily: "Inter",
      fontSize: "20px",
      color: "#535655",
  },
  "& .loginPswd": {
      fontWeight: 400,
      fontStyle: "normal",
      color: "var(--grey-base, #939393)",
      fontFamily: "Jost",
      fontSize: "16px",
     
  },
  "& .btn-grid": {
      textAlign: "center"
  },
  "& .loginBtn": {
    border: "2px solid #A046FB",
    width: '90%',
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    borderRadius: "2px",
    background: "#A046FB",
    height: '48px',
    boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
    color: '#fff'
  
   
},
  "& .forgetPswd": {
      cursor: 'pointer',
      color: "#A046FB",
      fontWeight: 500,
      fontFamily: "Inter",
      fontStyle: "normal",
      width: "90%",
    },
    "& .change-password-btn": {
      height: "50px",
      color: "#000",
      fontFamily: "Jost",
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: "bolder",
      marginBottom: "40px",
      borderRadius: "4px",
      border: "2px solid #A046FB",
      background: "#FFF",
      boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
      marginTop: "32px",
    },
    "& .ConfirmNewPswd": {
      scrollSnapMarginTop: "20px",
      marginTop: "20px",
      fontFamily: "Jost",
      height: "49px",
    },
    "& .registrationLogo img" : {
      cursor : "pointer"
    }
  });
  const TermsDialogStyle = styled((Box as React.FC<BoxProps>))({

    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },
    "& .termContainer": {
        width: '100%',
        maxWidth: '1100px',
    },
    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
   
    " & .termsText": {
        color: "#000",
        fontSize: "28px",
        fontStyle: "normal",
        fontFeatureSettings: "'clig' off, 'liga' off",
        lineHeight: "38px",
        fontFamily: "Jost",
       
        fontWeight: 700,
    },
    "& .dialogContentPara": {
      fontFamily: "Inter",
      paddingTop: '20px',
      color: "#3D3C3C",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "26px",
      fontStyle: "normal",
      marginBottom: '10px'
  },
    "& .dialogContent": {
        fontSize: "14px",
        color: "#3D3C3C",
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "26px",
        fontFamily: "Inter",
        
    },
    "& .box": {
    },
})
const DialogStyle = styled(Box)({
  "& .passwordError" : {
      maxWidth:"350px",
      fontSize:"12px",
      color:"#F44336",
      fontFamily:"Inter",
      marginTop:"5px",
      marginLeft:"14px"
  },
  "& .dialog-para": {
    fontStyle: "normal",
    width: '90%',
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    color: "#5E5E5E",
   
},
  "& .dialog-title": {
      fontSize: "28px",
      color: "#000",
      fontFamily: "Jost",
      fontWeight: "bolder",
      
  },

  "& .newPswd": {
    marginTop: '24px',
    fontFamily: 'Jost',
    height: '49px',
    
},
  "& .change-password-btn": {
      borderRadius: "4px",
      textTransform: 'capitalize',
      fontFamily: 'Jost',
      marginTop: '32px',
      fontSize: '14px',
      fontWeight: 'bolder',
      height: '50px',
      marginBottom: '40px',
      border: "2px solid #A046FB",
      background: "#FFF",
      boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
      color: '#000',
  },
  "& .ConfirmNewPswd": {
      fontFamily: 'Jost',
      scrollSnapMarginTop: '20px',
      height: '49px',
      marginTop: '20px',
  },
 
})
const PrivacyDialogStyle = styled((Box as React.FC<BoxProps>))({
  "& .MuiDialogContent-root": {
      padding: '8px 40px'
  },
  "& .dialogContent": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    color: "#3D3C3C",
    fontStyle: "normal",
    lineHeight: "26px"
},
"& .box": {
},

"& .dialogContentPara": {
    color: "#3D3C3C",
    fontFamily: "Inter",
    paddingTop: '20px',
    fontSize: "14px",
    lineHeight: "26px",
    marginBottom: '10px',
    fontStyle: "normal",
    fontWeight: 400,

},
  "& .MuiDialogTitle-root": {
      padding: '0 40px'
  },
  " & .privacyText": {
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Jost",
      color: "#000",
      fontSize: "28px",
      lineHeight: "38px",
      fontStyle: "normal",
      fontWeight: 700,
  },
  "& .privacyContainer": {
      width: '100%',
      maxWidth: '1100px',
  }


})
// Customizable Area End