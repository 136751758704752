Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getStudyApiCallIdEndPoint = "bx_block_catalogue/studies/show_study";
exports.getApiMethod = "GET";
exports.getAllPatientsApi = "bx_block_catalogue/patients_studies"
exports.getAllPatientsDataApi = "bx_block_dashboard/dashboards/graph"
exports.getSinglePatientDataApi = "bx_block_dashboard/dashboards/patient_detail?account_id="
exports.getRewardsPointApi = "bx_block_dashboard/dashboards/point_reward_graph?account_id="
exports.getAllMilestoneShowApiEndPoint = "bx_block_catalogue/studies/all_milestone_show/"
exports.getFeedbackApiCallEndPoint = "bx_block_content_management/task_list_feedbacks?patient_id="

exports.translations = {
  fr: {
    Study_Number : "Study Number",
    Site_Coordinator : "Site Coordinator",
    Site_Country : "Site Country",
    Points: "Points",
    Milestone_Details : "Milestone Details",
    Site_Details : "Site Details",
    End_Date : "End Date",
    Dashboard : "f-Dashboard",
    Site_ID : "Site ID",
    Milestone : "Milestone",
    Total: "Total",
    Duration: "Duration",
    Site_Address : "Site Address",
    Start_Date : "Start Date",
    Current_Milestone : "Current Milestone",
    Patients : "f-Patients",
    Patients_Stats : "Patients Statistics",
    AttendedPatients : "Attended Patient according age",
    PercentageOfPatients : "Percentage of Patients",
    TaskSubmission : "Task Submission",
    NoDataFound : "No data found",
    Completed : "Completed",
    RecentlyAdded : "Recently Added Patients",
    NoPatientsFound : "No patient records have been added to display.",
    PatientId : " Patient ID",
    PointsEarned : "Points Earned",
    TotalPoints : "Total Points",
    EarnedPoints : "Earned Points",
    RewardsEarned : "Rewards Earned",
    TaskList : "Task List",
    Attended : "Attended",
    TaskAssigned : "Task Assigned",
    TaskAttended : "Task Attended",
    VisitSchedule : "Visit Schedule",

    DashboardDescription : "Welcome to your dashboard! View study details, milestone details progress, and site information all in one place.",
    Current: "Current",
    DetailOf: "Detail of",
    Site: "Site",

    TL: "TL",
    patientDescription : "Welcome to the Patients Page, You can view and manage patient information, track progress for the task list, and view individual patients in the study.",
    TotalPatients : "Total Patients",
    NotAttended : "Not Attended",
    
    TotalMilestones: "Total Milestones",
    CompletedMilestones: "Completed Milestones",
    PatientProgress : "Patient Progress against each milestone",
    SrNo : "Sr.No",
    PatientID : "Patient ID",
    patientReportPageDescription : "Welcome to the Patient Reports Page. Here, you’ll find details such as points earned, rewards redeemed, task list progress, milestone achievements, visit schedules, and study feedback for individual selected patient.",
    Earned : "Earned",
    Redeemed : "Redeemed",
    TotalEarned : "Total Earned",
    TotalRedeemed : "Total Redeemed",
    TaskListInformation : "Task List Information",
    Feedback: "Feedback",
    DateTime : "Date/Time",
    Rating : "Rating",
    Remarks : "Remarks",
    StudyNotFound: "Study feedback not found.",
    TrailAttended : "Trail Attended",
    Back : "Back",
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    showless : " show less",
    showmore : " show more...",
    Submitted: "Submitted",
    NotSubmitted : "Not Submitted",

  },
  en: {
    Study_Number : "Study Number",
    Site_Coordinator : "Site Coordinator",
    Site_Country : "Site Country",
    Points: "Points",
    Milestone_Details : "Milestone Details",
    Site_Details : "Site Details",
    End_Date : "End Date",
    Dashboard : "Dashboard",
    Site_ID : "Site ID",
    Milestone : "Milestone",
    Total: "Total",
    Duration: "Duration",
    Site_Address : "Site Address",
    Start_Date : "Start Date",
    Current_Milestone : "Current Milestone",
    Patients : "Patients",
    Patients_Stats : "Patients Statistics",
    AttendedPatients : "Attended Patient according age",
    PercentageOfPatients : "Percentage of Patients",
    TaskSubmission : "Task Submission",
    NoDataFound : "No data found",
    Completed : "Completed",
    RecentlyAdded : "Recently Added Patients",
    NoPatientsFound : "No patient records have been added to display.",
    PatientId : " Patient ID",
    PointsEarned : "Points Earned",
    TotalPoints : "Total Points",
    EarnedPoints : "Earned Points",
    RewardsEarned : "Rewards Earned",
    TaskList : "Task List",
    Attended : "Attended",
    TaskAssigned : "Task Assigned",
    TaskAttended : "Task Attended",
    VisitSchedule : "Visit Schedule",

    DashboardDescription : "Welcome to your dashboard! View study details, milestone details progress, and site information all in one place.",
    Current: "Current",
    DetailOf: "Detail of",
    Site: "Site",

    TL: "TL",
    patientDescription : "Welcome to the Patients Page, You can view and manage patient information, track progress for the task list, and view individual patients in the study.",
    TotalPatients : "Total Patients",
    NotAttended : "Not Attended",
    
    TotalMilestones: "Total Milestones",
    CompletedMilestones: "Completed Milestones",
    PatientProgress : "Patient Progress against each milestone",
    SrNo : "Sr.No",
    PatientID : "Patient ID",
    patientReportPageDescription : "Welcome to the Patient Reports Page. Here, you’ll find details such as points earned, rewards redeemed, task list progress, milestone achievements, visit schedules, and study feedback for individual selected patient.",
    Earned : "Earned",
    Redeemed : "Redeemed",
    TotalEarned : "Total Earned",
    TotalRedeemed : "Total Redeemed",
    TaskListInformation : "Task List Information",
    Feedback: "Feedback",
    DateTime : "Date/Time",
    Rating : "Rating",
    Remarks : "Remarks",
    StudyNotFound: "Study feedback not found.",
    TrailAttended : "Trail Attended",
    Back : "Back",
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    showless : " show less",
    showmore : " show more...",
    Submitted: "Submitted",
    NotSubmitted : "Not Submitted",

  },
  es: {
    Study_Number : "Study Number",
    Site_Coordinator : "Site Coordinator",
    Site_Country : "Site Country",
    Points: "Points",
    Milestone_Details : "Milestone Details",
    Site_Details : "Site Details",
    End_Date : "End Date",
    Dashboard : "s-Dashboard",
    Site_ID : "Site ID",
    Milestone : "Milestone",
    Total: "Total",
    Duration: "Duration",
    Site_Address : "Site Address",
    Start_Date : "Start Date",
    Current_Milestone : "Current Milestone",
    Patients : "s-Patients",
    Patients_Stats : "Patients Statistics",
    AttendedPatients : "Attended Patient according age",
    PercentageOfPatients : "Percentage of Patients",
    TaskSubmission : "Task Submission",
    NoDataFound : "No data found",
    Completed : "Completed",
    RecentlyAdded : "Recently Added Patients",
    NoPatientsFound : "No patient records have been added to display.",
    PatientId : " Patient ID",
    PointsEarned : "Points Earned",
    TotalPoints : "Total Points",
    EarnedPoints : "Earned Points",
    RewardsEarned : "Rewards Earned",
    TaskList : "Task List",
    Attended : "Attended",
    TaskAssigned : "Task Assigned",
    TaskAttended : "Task Attended",
    VisitSchedule : "Visit Schedule",

    DashboardDescription : "Welcome to your dashboard! View study details, milestone details progress, and site information all in one place.",
    Current: "Current",
    DetailOf: "Detail of",
    Site: "Site",

    TL: "TL",
    patientDescription : "Welcome to the Patients Page, You can view and manage patient information, track progress for the task list, and view individual patients in the study.",
    TotalPatients : "Total Patients",
    NotAttended : "Not Attended",
    
    TotalMilestones: "Total Milestones",
    CompletedMilestones: "Completed Milestones",
    PatientProgress : "Patient Progress against each milestone",
    SrNo : "Sr.No",
    PatientID : "Patient ID",
    patientReportPageDescription : "Welcome to the Patient Reports Page. Here, you’ll find details such as points earned, rewards redeemed, task list progress, milestone achievements, visit schedules, and study feedback for individual selected patient.",
    Earned : "Earned",
    Redeemed : "Redeemed",
    TotalEarned : "Total Earned",
    TotalRedeemed : "Total Redeemed",
    TaskListInformation : "Task List Information",
    Feedback: "Feedback",
    DateTime : "Date/Time",
    Rating : "Rating",
    Remarks : "Remarks",
    StudyNotFound: "Study feedback not found.",
    TrailAttended : "Trail Attended",
    Back : "Back",
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    showless : " show less",
    showmore : " show more...",
    Submitted: "Submitted",
    NotSubmitted : "Not Submitted",
  },
  de: {
    Study_Number : "Study Number",
    Site_Coordinator : "Site Coordinator",
    Site_Country : "Site Country",
    Points: "Points",
    Milestone_Details : "Milestone Details",
    Site_Details : "Site Details",
    End_Date : "End Date",
    Dashboard : "g-Dashboard",
    Site_ID : "Site ID",
    Milestone : "Milestone",
    Total: "Total",
    Duration: "Duration",
    Site_Address : "Site Address",
    Start_Date : "Start Date",
    Current_Milestone : "Current Milestone",
    Patients : "g-Patients",
    Patients_Stats : "Patients Statistics",
    AttendedPatients : "Attended Patient according age",
    PercentageOfPatients : "Percentage of Patients",
    TaskSubmission : "Task Submission",
    NoDataFound : "No data found",
    Completed : "Completed",
    RecentlyAdded : "Recently Added Patients",
    NoPatientsFound : "No patient records have been added to display.",
    PatientId : " Patient ID",
    PointsEarned : "Points Earned",
    TotalPoints : "Total Points",
    EarnedPoints : "Earned Points",
    RewardsEarned : "Rewards Earned",
    TaskList : "Task List",
    Attended : "Attended",
    TaskAssigned : "Task Assigned",
    TaskAttended : "Task Attended",
    VisitSchedule : "Visit Schedule",

    DashboardDescription : "Welcome to your dashboard! View study details, milestone details progress, and site information all in one place.",
    Current: "Current",
    DetailOf: "Detail of",
    Site: "Site",

    TL: "TL",
    patientDescription : "Welcome to the Patients Page, You can view and manage patient information, track progress for the task list, and view individual patients in the study.",
    TotalPatients : "Total Patients",
    NotAttended : "Not Attended",
    
    TotalMilestones: "Total Milestones",
    CompletedMilestones: "Completed Milestones",
    PatientProgress : "Patient Progress against each milestone",
    SrNo : "Sr.No",
    PatientID : "Patient ID",
    patientReportPageDescription : "Welcome to the Patient Reports Page. Here, you’ll find details such as points earned, rewards redeemed, task list progress, milestone achievements, visit schedules, and study feedback for individual selected patient.",
    Earned : "Earned",
    Redeemed : "Redeemed",
    TotalEarned : "Total Earned",
    TotalRedeemed : "Total Redeemed",
    TaskListInformation : "Task List Information",
    Feedback: "Feedback",
    DateTime : "Date/Time",
    Rating : "Rating",
    Remarks : "Remarks",
    StudyNotFound: "Study feedback not found.",
    TrailAttended : "Trail Attended",
    Back : "Back",
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    showless : " show less",
    showmore : " show more...",
    Submitted: "Submitted",
    NotSubmitted : "Not Submitted",


  },
};

// Customizable Area End