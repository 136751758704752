export const callLogo = require("../assets/callLogo.svg");
export const contactLogo = require("../assets/contactLogo.svg");
export const emailLogo = require("../assets/emailLogo.svg");
export const humanLogo = require("../assets/humanLogo.svg");
export const locationLogo = require("../assets/locationLogo.svg");
export const shieldLogo = require("../assets/shieldLogo.svg");
export const logoutLogo = require("../assets/logoutLogo.svg");
export const editLogo = require("../assets/editLogo.svg");
export const termsLogo = require("../assets/termsLogo.svg");
export const techHelp = require("../assets/techHelp.svg");
export const rightArrow = require("../assets/rightArrow.svg");
export const changeLogo = require("../assets/changeLogo.svg");
export const userLogo = require("../assets/profileAvatar.jpg");
export const cancelLogo = require("../assets/cancelLogo.svg");
export const contactEmailLogo = require("../assets/contactEmailLogo.svg");
export const contactPhoneLogo = require("../assets/contactPhoneLogo.svg");
export const dropdownLogo = require("../assets/button_dropdown.svg");
export const selectedLogo = require("../assets/selected_logo.svg");
export const buttonCancel = require("../assets/cancelLogo.svg");
export const headerLogo = require("../assets/image_logo.png");
export const headerUser = require("../assets/headerUser.svg");
export const lockLogo = require("../assets/lock_logo.svg");
export const rightLogo = require("../assets/rightArrow.svg");
export const PatientsIcon = require("../assets/image_r (2).png");
export const DashboradIcon = require("../assets/image_r (1).png");
export const Dashborad = require("../assets/dashboaed.svg");
export const Patient = require("../assets/patients.svg");
export const sponcer = require("../assets/sponsor.png");










