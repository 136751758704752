import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { changeLogo, contactLogo, logoutLogo, shieldLogo, techHelp, termsLogo } from "./assets";

export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: File | string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  detail: any
  open: boolean,
  passwordDialogOpen: boolean;
  editProfile: boolean;
  expanded: any;
  termsDialogOpen: boolean;
  privacyDialogOpen: boolean;
  techBarOpen: boolean;
  contactBarOpen: boolean;
  requestAdmin: boolean;
  oldPassword: any,
  newConfirmPassword: any,
  oldPasswordError: any,
  newPasswordError: any,
  newConfirmPasswordError: any,
  newPasswordSuccess: boolean;
  oldEmailAddress: any,
  newEmailAddress: any,
  newConfirmEmailAddress: any,
  oldEmailAddressError: any,
  newEmailAddressError: any,
  newConfirmEmailAddressError: any,
  addressLine1: string,
  addressLine2: string,
  addressLine3: string,
  phoneNumberError: any,
  fullNameError: any
  userEmail: any,
  userEmailError: any,
  contactFullName: any,
  contactFullNameError: any,
  contactUserEmail: any,
  contactUserEmailError: any,
  selectedReason: any,
  selectError: any,
  contactUserMessage: string,
  userDetails: any,
  termsAndConditions: any,
  privacyPolicy: any,
  technicalHelpData: any,
  imageToSent:File | null
  fileInput: File | null,
  fileObjectUrl: string,
  showOldPassword:boolean,
  showNewPassword:boolean,
  showNewConfirmPassword:boolean,
  stdInput:any,
  studyData: any,
  searchQuery:any,
  selectedStudy:any,
  studyDrawerOpen:boolean,
  activePage:string,
  showToast:boolean,
  milestoneData: any,
  isSessionExpired: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getUserProfileApiCallId: string = "";
  getTermsandConditionApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";
  getFaqApiCallId: string = "";
  contactFormApiCallId: string = "";
  editUserProfileApiCallId: string = "";
  changePasswordApiCallId: string = "";
  changeEmailApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      imageToSent:null,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      detail: [{
        dName: 'Change Password',
        id: "change-password",
        dImg: changeLogo,
      },
      {
        dName: 'Terms and Conditions',
        id: "terms-condt",
        dImg: termsLogo
      },
      {
        dName: 'Privacy Policies',
        id: "privacy-policy",
        dImg: shieldLogo
      },
      {
        dName: 'Technical Help',
        id: "tech-help",
        dImg: techHelp
      },
      {
        dName: 'Contact Us',
        id: "contact-us",
        dImg: contactLogo
      },
      {
        dName: 'Logout',
        id: "logout",
        dImg: logoutLogo
      }
      ],
      open: false,
      passwordDialogOpen: false,
      editProfile: false,
      expanded: false,
      termsDialogOpen: false,
      privacyDialogOpen: false,
      techBarOpen: false,
      contactBarOpen: false,
      requestAdmin: false,
      oldPassword: "",
      newConfirmPassword: "",
      oldPasswordError: "",
      newPasswordError: "",
      newConfirmPasswordError: "",
      newPasswordSuccess: false,
      oldEmailAddress: '',
      newEmailAddress: '',
      newConfirmEmailAddress: '',
      oldEmailAddressError: '',
      newEmailAddressError: '',
      newConfirmEmailAddressError: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      phoneNumberError: '',
      fullNameError: '',
      userEmail: '',
      userEmailError: '',
      contactFullName: '',
      contactFullNameError: '',
      contactUserEmail: "",
      contactUserEmailError: "",
      selectedReason: '',
      selectError: '',
      contactUserMessage: '',
      userDetails: null,
      termsAndConditions: null,
      privacyPolicy: null,
      technicalHelpData: null,
      fileInput: null,
      fileObjectUrl: '',
      showOldPassword:false,
      showNewPassword:false,
      showNewConfirmPassword:false,
      stdInput:'',
      studyDrawerOpen:false,
      selectedStudy:null,
      searchQuery:'',
      studyData : null,
      activePage: 'dashboard',
      showToast:false,
      milestoneData: null,
      isSessionExpired: false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getUserProfile();
    if(!localStorage.getItem("token")) this.props.navigation.navigate('EmailAccountRegistration');
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(apiRequestCallId === this.changePasswordApiCallId){
        this.handleChangePasswordValid(responseJson);
      }
      if (responseJson) {
        if (responseJson.errors) return;
        this.checkOtherResponses(message);
        this.checkProfileAccount(message);
      }
      if (apiRequestCallId != null && responseJson !== undefined) {
    
        switch (apiRequestCallId) {
          case this.getUserProfileApiCallId:                      
            this.setState({ userDetails: responseJson?.data?.attributes, 
              profilePicture:responseJson?.data?.attributes?.profile_image,
              fullName:responseJson.data.attributes.full_name,
              phoneNumber:responseJson.data.attributes.full_phone_number,
              address:responseJson.data.attributes.address
             });
            break;
          case this.getTermsandConditionApiCallId:
            this.setState({ termsAndConditions: responseJson.data.description });
            break;
          case this.getPrivacyPolicyApiCallId:
            this.setState({ privacyPolicy: responseJson.data.description });
            break;
          case this.getFaqApiCallId:
            this.setState({ technicalHelpData: responseJson.data });
            break;
          case this.editUserProfileApiCallId:
            this.getUserProfile()
            break;
          default:
        }
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate() {
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleClose = (name: string) => {
    switch (name) {
      case 'Change Password':
        this.setState({
          passwordDialogOpen: false, open: true,
          oldPassword: "", newPassword: "", newConfirmPassword: "",
          oldPasswordError: '', newPasswordError: '', newConfirmPasswordError: '',
          showOldPassword: false,
          showNewPassword: false,
          showNewConfirmPassword: false,
        });
        break;
      case 'Request Admin':
        this.setState({
          requestAdmin: false, open: true,
          oldEmailAddress: '', newEmailAddress: '', newConfirmEmailAddress: '',
          oldEmailAddressError: '', newEmailAddressError: '', newConfirmEmailAddressError: '',
          fullNameError: '', userEmailError: '', phoneNumberError: ''
        });
        break;
      case 'Terms and Conditions':
        this.setState({ termsDialogOpen: false, open: true });
        break;
      case 'Privacy Policies':
        this.setState({ privacyDialogOpen: false, open: true });
        break;
      case 'Technical Help':
        this.setState({ techBarOpen: false, open: true });
        break;
      case 'Contact Us':
        this.setState({
          contactBarOpen: false, open: true, contactFullName: '',
          contactFullNameError: '',
          contactUserEmail: "",
          contactUserEmailError: "",
          selectedReason: '',
          selectError: '',
          contactUserMessage: '',
        });
        break;
    }
  }

  handleProfilePictureChange = (event:any) => {
    const file = event.target.files[0];
    this.setState({
        profilePicture: URL.createObjectURL(file),
        imageToSent:file
    });
}


  handleOldPasswordChange = (value: any) => {
    const strongPasswordRegex = configJSON.passwordRegex;
    let oldPasswordError = '';
    if (value !== '') {
      oldPasswordError = strongPasswordRegex.test(value) ? '' : 'Old password is invalid!';
    }
    this.setState({
      oldPassword: value,
      oldPasswordError: oldPasswordError
    });
  }

  handleClickShowOldPassword = () => {
    this.setState((prevState) => ({
      showOldPassword: !prevState.showOldPassword
    }))
   }

   handleNewPasswordChange = (value: any) => {
    const strongPasswordRegex = configJSON.passwordRegex;
    let newPasswordError = '';
    if (value !== '') {
      newPasswordError = strongPasswordRegex.test(value)
        ? ''
        : 'Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!';
    }
    this.setState({
      newPassword: value,
      newPasswordError: newPasswordError,
      newConfirmPasswordError: value === this.state.newConfirmPassword ? '' : this.state.newConfirmPasswordError,
    });

  }

  handleClickShowNewPassword = () => {
    this.setState((prevState) => ({
      showNewPassword: !prevState.showNewPassword
    }))
   }

   handleNewConfirmPasswordChange = (value: any) => {
    this.setState({
      newConfirmPassword: value,
      newConfirmPasswordError: this.state.newPassword === value ? '' : 'Password do not match',
    });
  }

  handleClickShowNewConfirmPassword = () => {
    this.setState((prevState) => ({
      showNewConfirmPassword: !prevState.showNewConfirmPassword
    }))
   }

   newSubmitPassWord = () => {
    if (this.state.newPassword === "" && this.state.newConfirmPassword === "" && this.state.oldPassword === "") {
      this.setState({
        newPasswordError: "please enter password",
        newConfirmPasswordError: "please enter confirm password",
        oldPasswordError: 'please enter old password'
      })

    } else if (this.state.newPassword === "") {
      this.setState({ newPasswordError: "please enter password" })
    } else if (this.state.newConfirmPassword === "") {
      this.setState({ newConfirmPasswordError: "please enter confirm password" })
    } else if (this.state.newPassword !== this.state.newConfirmPassword) {
      this.setState({ newConfirmPasswordError: "Password do not match" })
    }
    else {
      this.changePassword({ current_password: this.state.oldPassword, new_password: this.state.newPassword }) 
    }
  }

  handleChangePasswordValid=(responseJson : any)=>{
    this.setState({
      oldPasswordError : responseJson.errors ? responseJson.errors[0].profile : ""
    },() =>{
    });
    
    if(this.state.oldPasswordError == ""){
      this.setState({showToast:true, open: true, passwordDialogOpen: false, oldPassword: "", newPassword: "", newConfirmPassword: "" });
      setTimeout(() => {
        this.setState({
          showToast:false
        })
      }, 3000);
     }
     else {
      this.setState({ oldPassword: this.state.oldPassword, newPassword: this.state.newPassword, newConfirmPassword: this.state.newConfirmPassword });
     }
  }

  changePassword = ({ current_password, new_password }: { current_password: string, new_password: string }) => {
    let token = localStorage.getItem("token")
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    }

    const httpBody = {
      data: {
        "current_password": current_password,
        "new_password": new_password
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOldEmailAddress = (value: any) => {
    const strongEmailRegex = configJSON.emailRegex;
    this.setState({
      oldEmailAddress: value,
      oldEmailAddressError: strongEmailRegex.test(value)
        ? ""
        : "Old Email is invalid!"
    })
  }

  handleNewEmailAddress = (value: any) => {
    const strongEmailRegex = configJSON.emailRegex;
    this.setState({
      newEmailAddress: value,
      newEmailAddressError: strongEmailRegex.test(value)
        ? ""
        : "Email must be valid!"
    })
  }

  handleNewConfirmEmailAddress = (value: any) => {
    this.setState({
      newConfirmEmailAddress: value,
      newConfirmEmailAddressError: this.state.newEmailAddress === value ? "" : 'Email does not match!'
    })
  }

  newSubmitEmailAddress = () => {
    if (this.state.newEmailAddress === "" && this.state.newConfirmEmailAddress === "" && this.state.oldEmailAddress === "") {
      this.setState({
        newEmailAddressError: 'please enter email',
        newConfirmEmailAddressError: 'please enter confirm email',
        oldEmailAddressError: 'please enter old email'
      })

    } else if (this.state.newEmailAddress === "") {
      this.setState({ newEmailAddressError: 'please enter email' })
    } else if (this.state.newConfirmEmailAddress === "") {
      this.setState({ newConfirmEmailAddressError: 'please enter confirm email' })
    } else if (this.state.newEmailAddress !== this.state.newConfirmEmailAddress) {
      this.setState({ newConfirmEmailAddressError: 'Email does not match!' })
    }
    else {
      this.changeEmail({ current_email: this.state.oldEmailAddress, new_email: this.state.newEmailAddress })

      this.setState({ open: true, requestAdmin: false, oldEmailAddress: '', newEmailAddress: '', newConfirmEmailAddress: '' })
    }
  }

  changeEmail = ({ current_email, new_email }: { current_email: string, new_email: string }) => {
    let token = localStorage.getItem("token")
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    }

    const httpBody = {
       
        "old_email": current_email,
        "new_email": new_email
      
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeEmailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeEmailApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClickOpen = (name: string) => {
    switch (name) {
      case 'Change Password':
        this.setState({ passwordDialogOpen: true, open: false });
        break;
      case 'Request Admin':
        this.setState({ requestAdmin: true, open: false });
        break;
      case 'Terms and Conditions':
        this.setState({ termsDialogOpen: true, open: false });
        this.getTermsAndCondition();
        break;
      case 'Privacy Policies':
        this.setState({ privacyDialogOpen: true, open: false });
        this.getPrivacyPolicy();
        break;
      case 'Technical Help':
        this.setState({ techBarOpen: true });
        this.getFaqs();
        break;
      case 'Contact Us':
        this.setState({ contactBarOpen: true });
        break;
      case 'Logout':
        this.handleLogout();
        break;
      default:
        break;
    }
  };

  handleDrawer = (page:any) =>{
    this.setState({ activePage: page });
  }

  getTermsAndCondition = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsandConditionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPrivacyPolicy = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFaqs = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFaqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFaqsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  handleFullNameEdit = (event: any) => {
    const strongNameRegex = configJSON.nameRegex;
    const newFullName = event.target.value;
    const isLengthValid = newFullName.length > 2;

    this.setState({
      fullName: newFullName,
      fullNameError: strongNameRegex.test(newFullName) && isLengthValid
        ? ""
        : 'Please enter a valid full name'
    })
  }

  handleUserNewEmail = (event: any) => {
    const strongEmailRegex = configJSON.emailRegex;
    const newUserEmail = event.target.value;

    this.setState({
      userEmail: newUserEmail,
      userEmailError: strongEmailRegex.test(newUserEmail)
        ? ""
        : "Please enter a valid email"
    })
  }

  handleEdit = (data:any) => {
    this.editUserProfile(data)
    this.setState({editProfile:true})
  }

  handlePhoneNumberChange = (event: any) => {
    const strongPhoneNumberRegex = configJSON.phoneNumberRegex;
    const newPhoneNumber = event.target.value;

    this.setState({
      phoneNumber: newPhoneNumber,
      phoneNumberError: strongPhoneNumberRegex.test(newPhoneNumber)
        ? ""
        : 'Please enter a valid phone number'
    });
  };

  handleUpdateDetailsClick = () => {

    const { fullName, phoneNumber, addressLine1, addressLine2, addressLine3  } = this.state;

    const isFullNameValid = configJSON.nameRegex.test(fullName) && fullName.length > 2;
    const isPhoneNumberValid = configJSON.phoneNumberRegex.test(phoneNumber);

    this.setState({
      fullNameError: isFullNameValid ? "" : 'Please enter a valid full name',
     phoneNumberError: isPhoneNumberValid ? "" : 'Please enter a valid phone number',
    });

    if (isFullNameValid) {
      this.editUserProfile({
        full_name: fullName,
        full_phone_number: phoneNumber,
        address: addressLine1 + ", " + addressLine2 + ", " + addressLine3,
        profile_image: this.state.imageToSent
      })
      this.setState({
        editProfile: false, open: true
      })
    }
  };

  editUserProfile = (data: any) => {
    let token = localStorage.getItem('token');
    const headers = {
      "token": token
    };

    let formdata = new FormData();
    formdata.append("full_name", this.state.fullName);
    formdata.append("full_phone_number", data.full_phone_number);
    formdata.append("address", data.address);
    formdata.append("profile_image", data.profile_image);
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editUserProfileApiEnd
    );

    this.editUserProfileApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleContactFullName = (event: any) => {
    const strongNameRegex = configJSON.nameRegex;
    const newContactFullName = event.target.value;
    const isLengthValid = newContactFullName.length > 2;

    this.setState({
      contactFullName: newContactFullName,
      contactFullNameError: strongNameRegex.test(newContactFullName) && isLengthValid
        ? ""
        : 'Please enter a valid full name'
    })
  }

  handleContactUserEmail = (event: any) => {
    const strongEmailRegex = configJSON.emailRegex;
    const newContactUserEmail = event.target.value;

    this.setState({
      contactUserEmail: newContactUserEmail,
      contactUserEmailError: strongEmailRegex.test(newContactUserEmail)
        ? ""
        : "Please enter a valid email"
    })
  }

  handleDropdownChange = (event: any) => {
    const newSelectedReason = event.target.value;
    const isError = newSelectedReason === '';

    this.setState({
      selectedReason: newSelectedReason,
      selectError: isError ? "Please select a reason" : ""
    });
  };

  handleContactSubmitButton = () => {
    const { contactFullName, contactUserEmail, selectedReason, contactUserMessage } = this.state

    const isContactFullNameValid = configJSON.nameRegex.test(contactFullName) && contactFullName.length > 2;
    const isContactUserEmailValid = configJSON.emailRegex.test(contactUserEmail);
    const isReasonValid = selectedReason !== ''

    this.setState({
      contactFullNameError: isContactFullNameValid ? "" : 'Please enter a valid full name',
      contactUserEmailError: isContactUserEmailValid ? "" : 'Please enter a valid email',
      selectError: isReasonValid ? "" : 'Please select a reason',
    });

    if (isContactFullNameValid && isContactUserEmailValid && selectedReason) {
      this.contactForm({
        name: contactFullName,
        email: contactUserEmail,
        reason: selectedReason,
        message: contactUserMessage
      })
      this.setState({
        contactBarOpen: false, open: true, contactFullName: '', contactUserEmail: '', selectedReason: '', contactUserMessage: ''
      })
    }
  }

  contactForm = (data: any) => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const httpBody = {
      "data": {
        "name": data.name,
        "email": data.email,
        "reason": data.reason,
        "message": data.message,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    this.contactFormApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
handleEditProfile=()=>{
  this.setState({editProfile:false});
}
  handleDrawerOpen = () => {
    this.setState({
      open: !this.state.open, editProfile: false,contactBarOpen: false, studyDrawerOpen: false, fullNameError: '', userEmailError: '', phoneNumberError: '',
      fullName: '', userEmail: '', phoneNumber: '', contactFullName: '',
      contactFullNameError: '',
      contactUserEmail: "",
      contactUserEmailError: "",
      selectedReason: '',
      selectError: '',
      techBarOpen: false
    });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleLogout = () => {
    localStorage.clear()
    this.props.navigation.navigate('EmailAccountRegistration')
  }

  getUserProfile = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserAccountApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleSessionExpired = () => {
    this.setState({ isSessionExpired: true });
  };

  // Customizable Area End
}
