Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getApiMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTemplates";
exports.labelBodyText = "ProjectTemplates Body";
exports.projectAPiEndPoint = "/bx_block_catalogue/studies/study_selection";
exports.StrongemailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.btnExampleTitle = "CLICK ME";
exports.phoneNumberRegex =  /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/ ;
exports.nameRegex = /^[a-zA-Z\s]+$/;
exports.getUserAccountApiEndPoint = "profile/user_profiles"
exports.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
exports.changePasswordApiEndPoint = "bx_block_profile/new_password_set"
exports.putApiMethod = "PUT";
exports.getTermsAndConditionApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions"
exports.apiContentType = "application/json";
exports.getNewPrivacyPolicyApiEndPoint = "bx_block_content_management/privacy_policies"
exports.getFaqsApiEndPoint = "bx_block_content_management/faqs"
exports.patchApiMethod = "PATCH"
exports.editUserProfileApiEnd = "bx_block_profile/profiles/update_profile"
exports.changeEmailApiEndPoint = "bx_block_profile/change_email"
exports.postApiMethod="POST"
exports.getStudyApiEndPoint = "bx_block_catalogue/studies/study_selection"
exports.getContactUsApiEndPoint = "bx_block_contact_us/contacts"

exports.translations = {
  fr: {
    SelectStudy : "Select the study",
    StudyNumber: "Study Number",
    NoStudyFound: "No Study Found",
    SearchStudyName: "Search Study Name",
    NoMatchFound: "No match found.",
    
    ChangePassword : "Change Password",
    ChangePasswordMsg: "Please enter your new password to reset.",
    EnterOldPassword : "Enter Old Password",
    EnterNewPassword : "Enter New Password",
    ConfirmNewPassword: "Confirm New Password",
    UpdatePassword: "Update Password",
    ChangeEmailRequest : "Change Email Request",
    ChangeEmailReqMsg : "Please enter your new email address to reset.",
    EnterOldEmail : "Enter Old Email",
    EnterNewEmail : "Enter New Email",
    ConfirmNewEmail : "Confirm New Email",
    SendRequest : "Send Request",
    Profile: "Profile",
    FullName : "Full Name",
    Email : "Email",
    Phone: "Phone",
    Address: "Address",
    TechnicalHelp: "Technical Help",
    EditProfile: "Edit Profile",
    ChangeProfilePicture : "Change profile picture",
    ImageUploadType : "Please upload valid image type.",
    EmailAddress: "Email Address",
    PhoneNumber: "Phone Number",
    UpdateDetails: "Update Details",
    ContactUs : "Contact Us",
    TalkCustomerSupport : "Talk with our Customer Support",
    TalkCustomerSupportDetails: "Get in touch with us for assistance, inquiries, or feedback. We’re here to help!",
    phoneNumberDigits: "+91 54321 1235",   
    AvailableTime: "Available 9:00 AM to 8:00 PM",
    SendYourQueries: "Send your queries!",
    Reason : "Reason",
    ReasonPlaceholder : "Enter reason",
    Message : "Message",
    TypeYourMsg : "Type your message here",
    Submit: "Submit",
    TandC : "Terms and Conditions",
    PrivacyPolicy : "Privacy Policy",
    Logout : "Logout",
    LogoutMsg : "Are you sure you want to Logout ?",
    Yes: "Yes",
    No: "No",
    SessionExpiredMsg1 : "Your session has expired",
    SessionExpiredMsg2 : " please login again.",
    Okay: "Okay",
    Study: "Study",


    oldPasswordErrorMsg: "Password must be at most 15 characters long!",
    oldPasswordErrorMsg2: "Old password is invalid!",
    passwordMsgValidation: "Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!",
    passwordMatchErrorMsg : "Password do not match",
    oldPasswordErrorMsg3 : "please enter old password",
    oldPasswordErrorMsg4: "please enter password",
    passwordMsg : "please enter confirm password",
    passwordMsg2 : "Please enter a new password different from the previous one.",
    EmailErrorMsg: "Email must be valid!",
    EmailErrorMsg2: "Old Email is invalid!",
    EmailErrorMsg3: 'Email does not match!',
    EmailErrorMsg5 : "Please enter a valid email",
    EmailValidationMsg: 'please enter email',
    EmailValidationMsg2: 'please enter confirm email',
    EmailValidationMsg3: 'please enter old email',
    phoneNumberValidationMsg: 'Please enter a valid phone number',
    FullNameValidationMsg: 'Please enter a valid full name',
    SelectReasonValidationMsg: "Please select a reason",

  },
  en:{
    SelectStudy : "Select the study",
    StudyNumber: "Study Number",
    NoStudyFound: "No Study Found",
    SearchStudyName: "Search Study Name",
    NoMatchFound: "No match found.",
    
    ChangePassword : "Change Password",
    ChangePasswordMsg: "Please enter your new password to reset.",
    EnterOldPassword : "Enter Old Password",
    EnterNewPassword : "Enter New Password",
    ConfirmNewPassword: "Confirm New Password",
    UpdatePassword: "Update Password",
    ChangeEmailRequest : "Change Email Request",
    ChangeEmailReqMsg : "Please enter your new email address to reset.",
    EnterOldEmail : "Enter Old Email",
    EnterNewEmail : "Enter New Email",
    ConfirmNewEmail : "Confirm New Email",
    SendRequest : "Send Request",
    Profile: "Profile",
    FullName : "Full Name",
    Email : "Email",
    Phone: "Phone",
    Address: "Address",
    TechnicalHelp: "Technical Help",
    EditProfile: "Edit Profile",
    ChangeProfilePicture : "Change profile picture",
    ImageUploadType : "Please upload valid image type.",
    EmailAddress: "Email Address",
    PhoneNumber: "Phone Number",
    UpdateDetails: "Update Details",
    ContactUs : "Contact Us",
    TalkCustomerSupport : "Talk with our Customer Support",
    TalkCustomerSupportDetails: "Get in touch with us for assistance, inquiries, or feedback. We’re here to help!",
    phoneNumberDigits: "+91 54321 1235",   
    AvailableTime: "Available 9:00 AM to 8:00 PM",
    SendYourQueries: "Send your queries!",
    Reason : "Reason",
    ReasonPlaceholder : "Enter reason",
    Message : "Message",
    TypeYourMsg : "Type your message here",
    Submit: "Submit",
    TandC : "Terms and Conditions",
    PrivacyPolicy : "Privacy Policy",
    Logout : "Logout",
    LogoutMsg : "Are you sure you want to Logout ?",
    Yes: "Yes",
    No: "No",
    SessionExpiredMsg1 : "Your session has expired",
    SessionExpiredMsg2 : " please login again.",
    Okay: "Okay",
    Study: "Study",


    oldPasswordErrorMsg: "Password must be at most 15 characters long!",
    oldPasswordErrorMsg2: "Old password is invalid!",
    passwordMsgValidation: "Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!",
    passwordMatchErrorMsg : "Password do not match",
    oldPasswordErrorMsg3 : "please enter old password",
    oldPasswordErrorMsg4: "please enter password",
    passwordMsg : "please enter confirm password",
    passwordMsg2 : "Please enter a new password different from the previous one.",
    EmailErrorMsg: "Email must be valid!",
    EmailErrorMsg2: "Old Email is invalid!",
    EmailErrorMsg3: 'Email does not match!',
    EmailErrorMsg5 : "Please enter a valid email",
    EmailValidationMsg: 'please enter email',
    EmailValidationMsg2: 'please enter confirm email',
    EmailValidationMsg3: 'please enter old email',
    phoneNumberValidationMsg: 'Please enter a valid phone number',
    FullNameValidationMsg: 'Please enter a valid full name',
    SelectReasonValidationMsg: "Please select a reason",



    },
  es: {
    SelectStudy : "Select the study",
    StudyNumber: "Study Number",
    NoStudyFound: "No Study Found",
    SearchStudyName: "Search Study Name",
    NoMatchFound: "No match found.",
    
    ChangePassword : "Change Password",
    ChangePasswordMsg: "Please enter your new password to reset.",
    EnterOldPassword : "Enter Old Password",
    EnterNewPassword : "Enter New Password",
    ConfirmNewPassword: "Confirm New Password",
    UpdatePassword: "Update Password",
    ChangeEmailRequest : "Change Email Request",
    ChangeEmailReqMsg : "Please enter your new email address to reset.",
    EnterOldEmail : "Enter Old Email",
    EnterNewEmail : "Enter New Email",
    ConfirmNewEmail : "Confirm New Email",
    SendRequest : "Send Request",
    Profile: "Profile",
    FullName : "Full Name",
    Email : "Email",
    Phone: "Phone",
    Address: "Address",
    TechnicalHelp: "Technical Help",
    EditProfile: "Edit Profile",
    ChangeProfilePicture : "Change profile picture",
    ImageUploadType : "Please upload valid image type.",
    EmailAddress: "Email Address",
    PhoneNumber: "Phone Number",
    UpdateDetails: "Update Details",
    ContactUs : "Contact Us",
    TalkCustomerSupport : "Talk with our Customer Support",
    TalkCustomerSupportDetails: "Get in touch with us for assistance, inquiries, or feedback. We’re here to help!",
    phoneNumberDigits: "+91 54321 1235",   
    AvailableTime: "Available 9:00 AM to 8:00 PM",
    SendYourQueries: "Send your queries!",
    Reason : "Reason",
    ReasonPlaceholder : "Enter reason",
    Message : "Message",
    TypeYourMsg : "Type your message here",
    Submit: "Submit",
    TandC : "Terms and Conditions",
    PrivacyPolicy : "Privacy Policy",
    Logout : "Logout",
    LogoutMsg : "Are you sure you want to Logout ?",
    Yes: "Yes",
    No: "No",
    SessionExpiredMsg1 : "Your session has expired",
    SessionExpiredMsg2 : " please login again.",
    Okay: "Okay",
    Study: "Study",


    oldPasswordErrorMsg: "Password must be at most 15 characters long!",
    oldPasswordErrorMsg2: "Old password is invalid!",
    passwordMsgValidation: "Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!",
    passwordMatchErrorMsg : "Password do not match",
    oldPasswordErrorMsg3 : "please enter old password",
    oldPasswordErrorMsg4: "please enter password",
    passwordMsg : "please enter confirm password",
    passwordMsg2 : "Please enter a new password different from the previous one.",
    EmailErrorMsg: "Email must be valid!",
    EmailErrorMsg2: "Old Email is invalid!",
    EmailErrorMsg3: 'Email does not match!',
    EmailErrorMsg5 : "Please enter a valid email",
    EmailValidationMsg: 'please enter email',
    EmailValidationMsg2: 'please enter confirm email',
    EmailValidationMsg3: 'please enter old email',
    phoneNumberValidationMsg: 'Please enter a valid phone number',
    FullNameValidationMsg: 'Please enter a valid full name',
    SelectReasonValidationMsg: "Please select a reason",

  },
  de: {
    SelectStudy : "Select the study",
    StudyNumber: "Study Number",
    NoStudyFound: "No Study Found",
    SearchStudyName: "Search Study Name",
    NoMatchFound: "No match found.",
    
    ChangePassword : "Change Password",
    ChangePasswordMsg: "Please enter your new password to reset.",
    EnterOldPassword : "Enter Old Password",
    EnterNewPassword : "Enter New Password",
    ConfirmNewPassword: "Confirm New Password",
    UpdatePassword: "Update Password",
    ChangeEmailRequest : "Change Email Request",
    ChangeEmailReqMsg : "Please enter your new email address to reset.",
    EnterOldEmail : "Enter Old Email",
    EnterNewEmail : "Enter New Email",
    ConfirmNewEmail : "Confirm New Email",
    SendRequest : "Send Request",
    Profile: "Profile",
    FullName : "Full Name",
    Email : "Email",
    Phone: "Phone",
    Address: "Address",
    TechnicalHelp: "Technical Help",
    EditProfile: "Edit Profile",
    ChangeProfilePicture : "Change profile picture",
    ImageUploadType : "Please upload valid image type.",
    EmailAddress: "Email Address",
    PhoneNumber: "Phone Number",
    UpdateDetails: "Update Details",
    ContactUs : "Contact Us",
    TalkCustomerSupport : "Talk with our Customer Support",
    TalkCustomerSupportDetails: "Get in touch with us for assistance, inquiries, or feedback. We’re here to help!",
    phoneNumberDigits: "+91 54321 1235",   
    AvailableTime: "Available 9:00 AM to 8:00 PM",
    SendYourQueries: "Send your queries!",
    Reason : "Reason",
    ReasonPlaceholder : "Enter reason",
    Message : "Message",
    TypeYourMsg : "Type your message here",
    Submit: "Submit",
    TandC : "Terms and Conditions",
    PrivacyPolicy : "Privacy Policy",
    Logout : "Logout",
    LogoutMsg : "Are you sure you want to Logout ?",
    Yes: "Yes",
    No: "No",
    SessionExpiredMsg1 : "Your session has expired",
    SessionExpiredMsg2 : " please login again.",
    Okay: "Okay",
    Study: "Study",


    oldPasswordErrorMsg: "Password must be at most 15 characters long!",
    oldPasswordErrorMsg2: "Old password is invalid!",
    passwordMsgValidation: "Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!",
    passwordMatchErrorMsg : "Password do not match",
    oldPasswordErrorMsg3 : "please enter old password",
    oldPasswordErrorMsg4: "please enter password",
    passwordMsg : "please enter confirm password",
    passwordMsg2 : "Please enter a new password different from the previous one.",
    EmailErrorMsg: "Email must be valid!",
    EmailErrorMsg2: "Old Email is invalid!",
    EmailErrorMsg3: 'Email does not match!',
    EmailErrorMsg5 : "Please enter a valid email",
    EmailValidationMsg: 'please enter email',
    EmailValidationMsg2: 'please enter confirm email',
    EmailValidationMsg3: 'please enter old email',
    phoneNumberValidationMsg: 'Please enter a valid phone number',
    FullNameValidationMsg: 'Please enter a valid full name',
    SelectReasonValidationMsg: "Please select a reason",


  }
}


// Customizable Area End