import React from "react";

// Customizable Area Start
import { Box, Button, Container, Grid, Hidden, InputAdornment, Paper, TextField, Typography, styled,CircularProgress, IconButton, BoxProps, Dialog, DialogActions, DialogTitle, DialogContent, MenuItem, Select } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { backDrop, boxheaderLogo, emailLogo, headerLogo, lockLogo, sideLogo,sponcer, languageLogo, dropdownLogo } from "../../email-account-registration/src/assets";
const ModalStyle = styled(Box)({
 
  "& .form-logo": {
    textAlign: "center",
    "@media (max-width: 600px)": { 
      marginBottom: "8px",
    }

  },

  "& .paper-container": {
    backgroundImage: `url(${backDrop})`,
    height: "100vh",
    overflowX: 'hidden',
    overflowY:"hidden"
  },

  "& .header": {
    backgroundColor: "#fff",
    padding: '30px 0',
  },
  "& .headerLogoMain" : {
    display:"flex",
    alignItems:"center"
},
  "& .main": {
    height: "calc(100vh - 200px)",
  },

  "& .paper": {
    textAlign: 'center',
    padding: 2,
  },

  "& .textPara": {
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    marginLeft: '20px',
    marginBottom: '100px',
    color: "#0F2220",
    fontFamily: "Jost",
    fontSize: "30px",


  },
  "& .box": {
    padding: 30,
    borderRadius: "10px",
    border: "2px solid #A046FB",
    alignItems: 'center',
    background: "#FFF",
    boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
    "@media (max-width: 600px)": { 
      padding: 20,
    }
  },

  "& .form-tnc": {
    textAlign: 'center',

  },
  "& .form-termsgroup": {
    fontFamily: 'Jost',
    fontWeight: 400,
    fontSize: '12px',
    color: "#2B2B2B",

  },
  "& .form-terms": {
    color: "#A046FB",
    fontFamily: 'Jost',
    fontWeight: 400,
    fontSize: '12px',
  },

  "& .languageTxt": {
    marginLeft: '4px',
    fontFamily: "Inter",
    fontSize: "14px",
    color: "#000",
    fontWeight: "bold",
    fontStyle: "normal",
  },
  "& .englishTxt": {
    fontStyle: "normal",
    color: "#A046FB",
    marginLeft: '4px',
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "bold",
        

  },

  "& .welcome": {
    fontStyle: "normal",
    
    fontSize: "20px",
    fontWeight: 600,

    color: "#535655",
    fontFamily:"Inter",
    "@media (max-width: 600px)": { 
      fontSize: "18px",
    }

  },

  "& .loginEmail": {
    height: '49px',
    marginTop: '20px',
    color: "var(--grey-base, #939393)",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "17px",
    "@media (max-width: 600px)": { 
      fontSize: "15px",
      marginBottom: '10px',
    }
  },
  "& .loginPswd": {
    height: '49px',
    color: "var(--grey-base, #939393)",
    fontFamily: "Jost",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "16px",
    "@media (max-width: 600px)": { 
      fontSize: "15px",
    }
  },

  "& .forgetPswd": {
    color: "#A046FB",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    textAlign: 'end',
  },

  "& .btn-grid": {
    textAlign: "center",

  },

  "& .loginBtn": {
    textTransform: 'capitalize',
    background: "#A046FB",
    boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
    height: '48px',
    width: '90%',
    borderRadius: "2px",
    border: "2px solid #A046FB",
    color: '#fff',
    fontWeight:"700",
    fontFamily:"Inter",
    "@media (max-width: 600px)": { 
      width: '100%',
    }
  },

})
const PrivacyDialogStyle = styled((Box as React.FC<BoxProps>))({
  "& .MuiDialogContent-root": {
      padding: '8px 40px'
  },
  " & .privacyText": {
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Jost",
    color: "#000",
    fontSize: "28px",
    lineHeight: "38px",
    fontStyle: "normal",
    fontWeight: 700,
},
  "& .MuiDialogTitle-root": {
      padding: '0 40px'
  },

  "& .privacyContainer": {
      width: '100%',
      maxWidth: '1100px',
  },
  "& .dialogContentPara": {
    color: "#3D3C3C",
    fontFamily: "Inter",
    paddingTop: '20px',
    fontSize: "14px",
    lineHeight: "26px",
    marginBottom: '10px',
    fontStyle: "normal",
    fontWeight: 400,

},

  "& .dialogContent": {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 400,
      color: "#3D3C3C",
      fontStyle: "normal",
      lineHeight: "26px"
  },
  "& .box": {
  },

 


})
const TermsDialogStyle = styled((Box as React.FC<BoxProps>))({

  "& .MuiDialogContent-root": {
      padding: '8px 40px'
  },
  "& .termContainer": {
      width: '100%',
      maxWidth: '1100px',
  },
  "& .MuiDialogTitle-root": {
      padding: '0 40px'
  },
 
  " & .termsText": {
      color: "#000",
      fontSize: "28px",
      fontStyle: "normal",
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: "38px",
      fontFamily: "Jost",
     
      fontWeight: 700,
  },
  "& .dialogContent": {
      fontSize: "14px",
      color: "#3D3C3C",
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "26px",
      fontFamily: "Inter",
      
  },
  "& .box": {
  },
  "& .dialogContentPara": {
      fontFamily: "Inter",
      paddingTop: '20px',
      color: "#3D3C3C",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "26px",
      fontStyle: "normal",
      marginBottom: '10px'
  },
})



const Logo = () => {
  return (
    <>
      <Grid item xs={12}>
        <Box className="form-logo">
          <img src={boxheaderLogo} alt="boxheaderLogo" />
        </Box>
      </Grid>
    </>

  )
}

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 Terms = () => {
  
  return (
      <Grid item xs={12}>
          <Box className="form-tnc">
              <Typography className="form-termsgroup">
              {this.state.languageSwitch.tnc}
                  <Typography className="form-terms"><Typography className="form-terms" style={{cursor:"pointer"}} component={'span'} id="handleTermsBtn" onClick={()=>this.handleTermsClick()} >{this.state.languageSwitch.terms}</Typography> 
                      <Typography className="form-termsgroup" component={"span"}> {this.state.languageSwitch.and} </Typography>
                      <Typography className="form-terms" style={{cursor:"pointer"}} component={'span'} id="handlePrivacyBtn"  onClick={()=>this.handlePrivacyClick()}>{this.state.languageSwitch.prp}</Typography></Typography>
                      {this.state.languageSwitch.last}
              </Typography>
             
          </Box>
      </Grid>
  )
}
privacyDialog = () => {
  
  const BoxView = Box as React.FC<BoxProps>
  return (
      <Dialog
          scroll="body"
          maxWidth={'lg'}
          open={this.state.privacyDialogOpen}
          className="privacyContainer"
          fullWidth
          aria-labelledby="responsive-dialog-title" >
          <PrivacyDialogStyle>
              <DialogActions>
                  <IconButton id="cancel-privacy" onClick={() => this.privacyDialogOpenClick()}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                  </IconButton>
              </DialogActions>
              <DialogTitle >
                  <Typography className='privacyText'>Privacy Policy</Typography>
              </DialogTitle>
              <DialogContent >
                  <BoxView className="box">
                      <div dangerouslySetInnerHTML={{ __html: this.state.privacyPolicy }} className="dialogContent"  />
                  </BoxView>
              </DialogContent>
          </PrivacyDialogStyle>
      </Dialog>
  )
}
language = () => {
  return (

      <Grid>
          <Box style={{marginTop: '33px', textAlign: 'center' }}>
            <div style={{alignContent:"center",display:"flex",justifyContent:"center"}}>
              <img src={languageLogo} height={20}  width={20} style={{ verticalAlign: 'top' }}  alt="Language-Logo" />
              <Typography className="languageTxt" component={"span"} >{this.state.languageSwitch.language}
                  <Select
            value={this.state.selectedLanguage}
            onClose={this.handleToggleLanguageMode}
            onChange={this.handleLanguageChange}
            className="englishtxt"
            open={this.state.languageMode}
            displayEmpty
            style={{
              marginLeft: '10px',
              borderBottom: 'none',
              verticalAlign: 'middle',
            }}
            IconComponent={() => null}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="de">German</MenuItem>
            <MenuItem value="fr">French</MenuItem>
            <MenuItem value="es">Spanish</MenuItem>
          </Select>
                      <img src={dropdownLogo} id="dropdownLogo" onClick={this.handleToggleLanguageMode}  alt="dropdown-logo" style={{marginLeft: '4px',  verticalAlign: 'middle', cursor: 'pointer' }} />
              </Typography>
              </div>
          </Box>
      </Grid>
  )
}

termsDialog = () => {

  const BoxView = Box as React.FC<BoxProps>
  return (
      <Dialog
          scroll="body"
          className="termContainer"
          open={this.state.termsDialogOpen}
          fullWidth
          maxWidth={'lg'}
          aria-labelledby="responsive-dialog-title" >
          <TermsDialogStyle>
              <DialogActions>
                  <IconButton id="cancel-terms" onClick={() => this.termsDialogOpenClick()}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                  </IconButton>
              </DialogActions>
              <DialogTitle >
                  <Typography className='termsText'>Terms and Conditions</Typography>
              </DialogTitle>
              <DialogContent >
                  <BoxView className="box">
                      <div className="dialogContent" dangerouslySetInnerHTML={{ __html: this.state.termsAndConditions }} />
                  </BoxView>
              </DialogContent>
          </TermsDialogStyle>
      </Dialog>
  )
}

 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ModalStyle>
          <Paper elevation={0} className="paper-container">
            <Box style={{ position: 'sticky', left: 0, right: 0, top: 0, }}>
              <header className="header">
                <Container maxWidth="lg">
                <Grid item  >
                  <img src={headerLogo} id="logoheading" style={{cursor:"pointer"}} alt="headerLogo" width={144} onClick={this.navigateToSignUp} />
                  <img src={sponcer} style={{ marginLeft: "8px", cursor:"pointer" }} onClick={this.navigateToSignUp}/>
                </Grid>
                </Container>
              </header>
            </Box>
            <Container maxWidth="lg">

              <Grid container className="main" alignItems="center">
                <Hidden smDown>
                  <Grid item xs={12} md={6}>
                    <Typography className="textPara" id="textParamain">  {this.state.languageSwitch.pageHeader} </Typography>
                    <img src={sideLogo} alt="sideLogo" />
                  </Grid>
                </Hidden>


                <Grid container item xs={12} md={6} justifyContent="center" className="loginBox" >
                  <Grid item xs={10} >
                    <Box className="box" >
                      <Grid spacing={3} container  >
                       <Logo/>
                        <Grid item xs={12}>
                          <Box className="form-logo">
                            <Typography className="welcome" >{this.state.languageSwitch.welcomeMsg} </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            placeholder="Email Address"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={emailLogo} />
                                </InputAdornment>
                              ),
                              style: { height: 49, backgroundColor:"#f4f4f4", border:"1px solid #e6e6e6" },
                              className: 'loginEmail'
                            }}
                            value={this.state.email}
                            disabled={true}
                            error={this.state.emailError}
                            helperText={
                              !this.state.emailError ? "" : this.state.emailErrorText
                            }
                            id="email"
                          />

                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            type={this.state.showPasswordLogin ? 'text' : 'password'}                                
                            id="password"
                            variant="outlined"
                            placeholder={this.state.languageSwitch.enterPassPh}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={lockLogo} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                    {this.state.password.length > 0 ? (<IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => this.handleClickShowPasswordLogin()}
                                    >
                                        {this.state.showPasswordLogin ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>) : null}
                                </InputAdornment>
                            ),
                              className: 'loginPswd'
                            }}
                            onChange={(event) => { this.handlePasswordChange(event.target.value) }}
                            value={this.state.password}
                            error={this.state.passwordError}
                            helperText={this.state.passwordError ? this.state.passwordErrorText:""}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography style={{fontFamily:"Inter"}} className="forgetPswd" id="forgetPswd" >
                             <span style={{cursor:"pointer"}} onClick={() => this.handleForgetPassword()}>{this.state.languageSwitch.forgotPass}</span> 
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} className="btn-grid">
                          <Button variant="outlined"
                            className="loginBtn"
                            id="loginBtn"
                            onClick={() => this.handleLoginSubmit()}
                          > {this.state.isLoader ? <CircularProgress size={30} style={{color:'#fff'}} /> :`${this.state.languageSwitch.login}`}</Button>
                        </Grid>
                        {this.Terms()}
                      </Grid>
                    </Box>
                    {this.language()}
                  </Grid>
                </Grid>

              </Grid>
            </Container>
          </Paper>
          {this.termsDialog()}
          {this.privacyDialog()}
        </ModalStyle>
       // Customizable Area End
    );
  }
}
