// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTest";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Search from "../../blocks/search/src/Search";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Videos from "../../blocks/videos/src/Videos";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import CommunityForum from "../../blocks/communityforum/src/CommunityForum";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Cffortabletcompatibilityandroidios1 from "../../blocks/cffortabletcompatibilityandroidios1/src/Cffortabletcompatibilityandroidios1";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LandingPageBlock from "../../blocks/landingpage/src/LandingPage.web"
import EmailAccountRegistrationBlock from "../../blocks/email-account-registration/src/EmailAccountRegistration.web"
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web"
import ProjectTemplates from "../../blocks/projecttemplates/src/ProjectTemplates.web"



const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Search:{
 component:Search,
path:"/Search"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Videos:{
 component:Videos,
path:"/Videos"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
// Splashscreen:{
//  component:Splashscreen,
// path:"/Splashscreen"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
// GroupVideoCall:{
//  component:GroupVideoCall,
// path:"/GroupVideoCall"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Cffortabletcompatibilityandroidios1:{
 component:Cffortabletcompatibilityandroidios1,
path:"/Cffortabletcompatibilityandroidios1"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
EmailAccountRegistrationBlock:{
  component:EmailAccountRegistrationBlock,
  path:"/",
  exact: true
},
LandingPageBlock:{
  component:LandingPageBlock,
  path:"/LandingPageBlock"
},
CustomisableUserProfiles:{
  component:CustomisableUserProfiles,
  path:"/CustomisableUserProfiles"
},
ProjectTemplates:{
  component:ProjectTemplates,
  path:"/ProjectTemplates"
},


  // Home: {
  //   component: HomeScreen,
  //   path: '/Home',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    const outerRoutes = [ "/", '/EmailAccountLoginBlock', '/EmailAccountRegistration', '/ForgotPassword'];
    const isLoggedIn = localStorage.getItem('token');
    const currentRoute = document.location.pathname;
    
    if (isLoggedIn) {
      if (outerRoutes.includes(currentRoute)) {
        document.location.pathname = '/ProjectTemplates';
      }
    } else {
      if (!outerRoutes.includes(currentRoute)) {
        document.location.pathname = '/';
      } else {
      }
    }
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        {/* <ModalContainer /> */}
      </View>
    );
  }
}

export default App;