export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const headerLogo = require("../assets/image_logo.png");
export const sideLogo = require("../assets/side-logo.svg");
export const boxheaderLogo =  require("../assets/boxheader_logo.svg");
export const emailLogo = require("../assets/emailLogo.svg");
export const backDrop = require("../assets/backDrop.png");
export const lockLogo = require("../assets/lock_logo.svg")
export const sponcer = require("../assets/sponsor.png");
export const languageLogo = require("../assets/langauge.png");
export const dropdownLogo = require("../assets/button_dropdown.svg");
