import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

interface PatientData {
  id: string;
  full_name: string;
  id_number: string | null;
}
interface PatientTask {
  task_id: string;
  completed_patients_count: number;
}
interface AllPatientsDataApiType{
  total_patient_count: number;
  patients_with_completed_tasks: PatientTask[];
  total_milestone_count: number;
  completed_milestones: number;
}

interface TaskSubmDataType {
  name: string;
  Attended: number;
  NotAttended: number;
}
interface PatientTableDataType {
  srNo: string;
  patientId: string;
  report: string;
  idnum : string;
  name : string
}

interface PatientTaskTableDataType{
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "account_id": number,
      "task_list_id": number,
      "rating": number,
      "comments": string,
      "created_at":Date,
      "updated_at":Date
  }
}

interface SinglePatientDataType {
  account_id: number;
  account_full_name: string;
  account_id_number: number | null;
  site_id: number;
  site_number: string;
  site_coordinator_account_full_name: string;
  task_list: {
    task_assigned_point: number;
    task_attended: number;
  };
  milestone_managements: {
    total_milestones: number;
    completed_milestones: number;
  };
  visit_schedules: {
    total_visit: number;
    scheduled: number;
    attended: number;
    not_attended: number;
  };
  task_list_information: {
    submitted_task: submitted_taskType[],
    not_submitted_task: submitted_taskType[]
  }
}

type submitted_taskType = {
    "id": number,
    "task_name":string,
    "description":string,
    "study_id":number,
    "milestone_id":number,
    "created_at":string,
    "updated_at":string,
    "published":boolean,
    "total_points":number,
    "task_feedback":boolean,
    "submitted": boolean,
    "isEmpty": boolean,
}


interface VisitScheduleType {
  name: string;
  uv: number;
}
interface GetAllPatientsResponse {
  data: {
    id: string;
    attributes: {
      full_name: string;
      id_number: string;
    }
  }
}
interface RewardsPointsType {
  points_earned: {
    total_point: number;
    earned_points: number;
  };
  rewards_points:{
    total_earn: number;
    total_redeem: number;
  }
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  onSessionExpired: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  selectedStudy: string | null;
  Patients: PatientData[];
  flag: number;
  totalPatients : number,
  totalMilestones : number,
  completedMilestones : number,
  taskSubmissionData : TaskSubmDataType[],
  patientTableData : PatientTableDataType[],
  patientName : string,
  singlePatientData : SinglePatientDataType,
  visitScheduleData : VisitScheduleType[],
  rewardsPoints: RewardsPointsType,
  patientID : string,
  languageSwitch : {
    Patients: string;
    Patients_Stats: string;
    AttendedPatients: string;
    PercentageOfPatients: string;
    TaskSubmission: string;
    NoDataFound: string;
    Completed: string;
    RecentlyAdded: string;
    NoPatientsFound: string;
    PatientId: string;
    PointsEarned: string;
    TotalPoints: string;
    EarnedPoints: string;
    RewardsEarned: string;
    TaskList: string;
    Attended: string;
    TaskAssigned: string;
    TaskAttended: string;
    VisitSchedule: string;
    Milestone: string;
    TL: string;
    patientDescription: string;
    TotalPatients: string;
    NotAttended: string;

    StudyNotFound: string;
    Remarks: string;
    Rating: string;
    DateTime: string;
    SrNo: string;
    Feedback: string;
    CompletedMilestones: string;
    TotalMilestones: string;
    TaskListInformation: string;
    TotalRedeemed: string;
    TotalEarned: string;
    Redeemed: string;
    Earned: string;
    Site_Coordinator: string;
    Site_ID: string;
    PatientID: string;
    patientReportPageDescription: string;
    Back: string;
    PatientProgress: string;
    TrailAttended: string;
    showless : string,
    showmore : string,
    Submitted: string,    
    NotSubmitted : string,
  },
  selectedLanguage: string;
  taskFeedbackPatient: PatientTaskTableDataType[];
  expandIndex: number|null;
  taskListInformation: submitted_taskType[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      selectedStudy: "",
      Patients: [],
      flag: 0,
      totalPatients : 0,
      totalMilestones : 0,
      completedMilestones : 0,
      taskSubmissionData : [],
      patientTableData: [],
      patientName : '',
      singlePatientData: {
        account_id: 0,
        account_full_name: "",
        account_id_number: null,
        site_id: 0,
        site_number: "",
        site_coordinator_account_full_name: "",
        task_list: {
          task_assigned_point: 0,
          task_attended: 0
        },
        milestone_managements: {
          total_milestones: 0,
          completed_milestones: 0
        },
        visit_schedules: {
          total_visit: 0,
          scheduled: 0,
          attended: 0,
          not_attended: 0
        },
        task_list_information:{
          submitted_task: [],
          not_submitted_task: []
        }
      },
      visitScheduleData: [
        { name: "Total Visits", uv: 0 },
        { name: "Scheduled", uv: 0 },
        { name: "Attended", uv: 0 }
      ],
      rewardsPoints: {
        points_earned: {
          total_point: 0,
          earned_points: 0,
        },
        rewards_points:{
          total_earn: 0,
          total_redeem: 0,
        }
      },
      patientID : "",
      languageSwitch : {
        Patients: "",
        Patients_Stats: "",
        AttendedPatients: "",
        PercentageOfPatients: "",
        TaskSubmission: "",
        NoDataFound: "",
        Completed: "",
        RecentlyAdded: "",
        NoPatientsFound: "",
        PatientId: "",
        PointsEarned: "",
        TotalPoints: "",
        EarnedPoints: "",
        RewardsEarned: "",
        TaskList: "",
        Attended: "",
        TaskAssigned: "",
        TaskAttended: "",
        VisitSchedule: "",
        Milestone: "",
        TL: "",
        patientDescription: "",
        TotalPatients: "",
        NotAttended: "",

        StudyNotFound: "",
        Remarks: "",
        Rating: "",
        DateTime: "",
        SrNo: "",
        Feedback: "",
        CompletedMilestones: "",
        TotalMilestones: "",
        TaskListInformation: "",
        TotalRedeemed: "",
        TotalEarned: "",
        Redeemed: "",
        Earned: "",
        Site_Coordinator: "",
        Site_ID: "",
        PatientID: "",
        patientReportPageDescription: "",
        Back: "",
        PatientProgress: "",
        TrailAttended: "",
        showless : "",
        showmore : "",
        Submitted: "",    
        NotSubmitted : "",
      },
      selectedLanguage: "",
      taskFeedbackPatient: [],
      expandIndex: null,
      taskListInformation: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const handleRestApiResponse = (message: Message) => {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if(responseJson?.errors?.is_token_expired){
          return this.props.onSessionExpired();
        }

        if (apiRequestCallId === this.getAllPatientsApiCallId) {
          const accountsData = responseJson.map((item: GetAllPatientsResponse) => ({
              id: item.data.id,
              full_name: item.data.attributes.full_name,
              id_number: item.data.attributes.id_number,
          }));

          this.setState({ Patients: accountsData }, () => {
            const patientTableDataNew = this.state.Patients.map((patient, indexing) => {
                const srNo = (indexing + 1).toString().padStart(2, "0");
                const patientId = patient.id_number || "Null";
                const report = "Reports";
                const idnum = patient.id;
                const name = patient.full_name;
                return { srNo, patientId, report, idnum, name };
            });
            this.setState({ patientTableData: patientTableDataNew });
          });
          
        }
        else if(apiRequestCallId === this.getAllPatientsDataApiCallId){
          this.handleGetAllPatientsDataApiResponse(responseJson)
        }
        else if(apiRequestCallId === this.getSinglePatientsDataApiCallId){
          this.handleSinglePatientsResponse(responseJson);
        }
        else if(apiRequestCallId === this.getRewardsPointsDataApiCallId){
          this.handleRewardsPointsApiData(responseJson)
        } else if(apiRequestCallId == this.getFeedbackApiCallId){
          this.setState({
            taskFeedbackPatient: responseJson.data
          })
        }
      }
    };

    handleRestApiResponse(message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msgs: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msgs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgs);
  }
  getAllPatientsApiCallId: string = "";
  getAllPatientsDataApiCallId: string = "";
  getSinglePatientsDataApiCallId: string = "";
  getRewardsPointsDataApiCallId: string = "";
  getFeedbackApiCallId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getSelectedLanguage();
    this.setState(
      {
        selectedStudy: window.localStorage.getItem("studyNumber"),
      },
      () => {
        this.getAllPatients();
        this.getAllPatientsData();
      }
    );
  }
  getAllPatients = () => {
    let token =
      typeof window !== "undefined"
        ? window.localStorage.getItem("token")
        : null;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPatientsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllPatientsApi}/${this.state.selectedStudy}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFeedbackApiCall = async (idNumber : string) => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFeedbackApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFeedbackApiCallEndPoint}${idNumber}&study_id=${this.state.selectedStudy}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllPatientsData = async () => {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPatientsDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllPatientsDataApi}?study_id=${this.state.selectedStudy}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleFlagChange = (rows : PatientTableDataType) => {
    this.setState({ flag: 1, patientName : rows.name, patientID:rows.patientId });
    this.getSinglePatientData(rows.idnum);
    this.getRewardsPointsDataApi(rows.idnum);
    this.getFeedbackApiCall(rows.idnum)
    this.scrollToTop();
  };
  handleGetAllPatientsDataApiResponse = (responseJson : AllPatientsDataApiType)=>{
    this.setState({
      totalPatients : responseJson.total_patient_count,
      totalMilestones : responseJson.total_milestone_count,
      completedMilestones : responseJson.completed_milestones
    })
    const transformedData: TaskSubmDataType[] = responseJson?.patients_with_completed_tasks.map(task => ({
        name: task.task_id,
        Attended: task.completed_patients_count,
        NotAttended: this.state.totalPatients - task.completed_patients_count,
      }));

    this.setState({ taskSubmissionData: transformedData });
  }
  getSinglePatientData = async(idNumber : string)=>{
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSinglePatientsDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSinglePatientDataApi}${idNumber}&study_id=${this.state.selectedStudy}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSinglePatientsResponse=(responseJson : SinglePatientDataType)=>{

    let mergedTasks = [...responseJson.task_list_information.submitted_task.map((task: any) => ({ ...task, submitted: true, isEmpty: false }))];

    responseJson.task_list_information.not_submitted_task.forEach((notSubmitted : any) => {
      const existingTaskIndex = mergedTasks.findIndex(submitted => submitted.id === notSubmitted.id);
      if (existingTaskIndex === -1) {
         mergedTasks.push({ ...notSubmitted, submitted: false, isEmpty: false });
      }
    });

  
    if(mergedTasks.length > 0 && mergedTasks.length < 20){
     const taskLength = 20 - mergedTasks.length;
     for(let i = 0; i < taskLength; i++){
      mergedTasks.push({
        isEmpty: true
      })
     }
    }


    this.setState({ singlePatientData: responseJson, taskListInformation: mergedTasks });
    const { total_visit, scheduled, attended } = responseJson.visit_schedules;
    const updatedBarData2 = [
      { name: "Total Visits", uv: total_visit },
      { name: "Scheduled", uv: scheduled },
      { name: "Attended", uv: attended }
    ];
    this.setState({ visitScheduleData: updatedBarData2 });
  }
  handleNewFlagChange=()=>{
    this.setState({flag : 0})
  }

  getRewardsPointsDataApi = async(idNumber : string)=>{
    let token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRewardsPointsDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRewardsPointApi}${idNumber}&study_id=${this.state.selectedStudy}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleRewardsPointsApiData = (responseJson : RewardsPointsType)=>{
    this.setState({
        rewardsPoints : responseJson
      })
  }
  getSelectedLanguage = async () => {
    let selLanguage = await getStorageData('lang') || "en";
    this.setState({ selectedLanguage: selLanguage }, () => {
      this.getInitialValues();
    });
  };
  getInitialValues = ()=>{
    const translations = configJSON.translations[this.state.selectedLanguage];

    this.setState({
      languageSwitch: {
        Patients: translations.Patients,
        Patients_Stats: translations.Patients_Stats,
        AttendedPatients: translations.AttendedPatients,
        PercentageOfPatients: translations.PercentageOfPatients,
        TaskSubmission: translations.TaskSubmission,
        NoDataFound: translations.NoDataFound,
        Completed: translations.Completed,
        RecentlyAdded: translations.RecentlyAdded,
        NoPatientsFound: translations.NoPatientsFound,
        PatientId: translations.PatientId,
        PointsEarned: translations.PointsEarned,
        TotalPoints: translations.TotalPoints,
        EarnedPoints: translations.EarnedPoints,
        RewardsEarned: translations.RewardsEarned,
        TaskList: translations.TaskList,
        Attended: translations.Attended,
        TaskAssigned: translations.TaskAssigned,
        TaskAttended: translations.TaskAttended,
        VisitSchedule: translations.VisitSchedule,
        Milestone: translations.Milestone,
        TL: translations.TL,
        patientDescription: translations.patientDescription,
        TotalPatients: translations.TotalPatients,
        NotAttended: translations.NotAttended,

        StudyNotFound: translations.StudyNotFound,
        Remarks: translations.Remarks,
        Rating: translations.Rating,
        DateTime: translations.DateTime,
        SrNo: translations.SrNo,
        Feedback: translations.Feedback,
        CompletedMilestones: translations.CompletedMilestones,
        TotalMilestones: translations.TotalMilestones,
        TaskListInformation: translations.TaskListInformation,
        TotalRedeemed: translations.TotalRedeemed,
        TotalEarned: translations.TotalEarned,
        Redeemed: translations.Redeemed,
        Earned: translations.Earned,
        Site_Coordinator: translations.Site_Coordinator,
        Site_ID: translations.Site_ID,
        PatientID: translations.PatientID,
        patientReportPageDescription: translations.patientReportPageDescription,
        Back: translations.Back,
        PatientProgress: translations.PatientProgress,
        TrailAttended: translations.TrailAttended,
        showless : translations.showless,
        showmore : translations.showmore,
        Submitted: translations.Submitted,    
        NotSubmitted : translations.NotSubmitted,
      }
    });
  
  }
  scrollToTop = () => {
    setTimeout(() => {
      if(typeof document !== "undefined"){
        const contactUsHeading = document.getElementById('topScroll');
            contactUsHeading?.scrollIntoView({ behavior: 'smooth' });
        
      }
    }, 0);
  };

  formatDate = (date : Date, locale : string) => {
  
    const day = date.getUTCDate().toString().padStart(2, '0'); // Get the day in 2-digit format
    const month = new Intl.DateTimeFormat(locale, { month: 'short' }).format(date) // Get the short month name
    const year = new Intl.DateTimeFormat(locale, { year: "numeric" }).format(date) // Get the short month name
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // Get minutes in 2-digit format
    const ampm = hours >= 12 ? 'pm' : 'am'; // Determine if it's AM or PM
    hours = hours % 12 || 12; // Convert to 12-hour format
  
    return `${year} ${day} ${month}, ${hours}:${minutes}${ampm}`; 
  };

  expandIndexFun = (index: number) =>{
    this.setState((prevState) => ({
      expandIndex: prevState.expandIndex === index ? null : index,
    }));
  }

  reviewView = (comments : string, index: number) => {
    if(comments){
      return this.state.expandIndex === index ? comments : `${comments.substring(0, 50)}`
    } else {
      return "";
    }
  }

  reviewExpand = (index : number, comments: string) =>{
    if( comments && comments.length > 49){
    return this.state.expandIndex === index ? this.state.languageSwitch.showless : this.state.languageSwitch.showmore
    }
  }

  taskListBgColor = (taskList: any) => {
    if (taskList.isEmpty) {
      return "#ffffff";
    } else if (taskList.submitted) {
      return '#a045fb';
    } else {
      return "#f0f0f0";
    }
  }

  taskListFontColor = (taskList : any) => {
    if(taskList.submitted){
      return '#FFFFFF';
    } else {
      return "#505050";
    }
  }

  taskListSubmitted = (taskList: any) =>{
    if(taskList.submitted){
       return this.state.languageSwitch.Submitted
     } else {
       return  this.state.languageSwitch.NotSubmitted
    }
  }

  rewardGraphValue = () => {
    return (this.state.rewardsPoints.rewards_points.total_redeem/this.state.rewardsPoints.rewards_points.total_earn || 0 )*100;
  }

  taskListAttended = () => {
    return (this.state.singlePatientData.task_list.task_attended/this.state.singlePatientData.task_list.task_assigned_point || 0)*100
  }
  // Customizable Area End
}
